<template> 
    <div class="pa-4">
        <!--Page Loader-->
        <pageLoader :is-page-loading="isPageLoading"/>
        <!--Filters-->

        <div class="d-flex" style="width:100%">
            <app-input input-type="textInput"
                style="width:100%;"
                class=""                             
                label="Name"
                :append-icon="'icons8-search'"
                clearable
                v-model.trim="searchName"/>
            <app-input v-if="$vuetify.breakpoint.width > 600" input-type="select"
                :items="sortPositions"
                style="width:100%;"
                class="ml-4"                                        
                label="Position"
                :append-icon="'icons8-search'"
                clearable
                v-model="searchPosition"/>
            <app-input v-if="$vuetify.breakpoint.width > 600" input-type="select"
                item-text="name"
                item-value="name"
                :items="skillsArray"
                style="width:100%;"
                class="ml-4"                                        
                label='Skill'
                :append-icon="'icons8-search'"
                clearable
                v-model="searchSkill"/>
            <app-input v-if="$vuetify.breakpoint.width > 600" input-type="textInput"
                style="width:100%;"
                class= "ml-4"                                        
                label='Date'
                clearable
                :append-icon="'icons8-search'"
                v-model.trim="searchDate"/>

            <app-input v-if="$vuetify.breakpoint.width > 600" input-type="select"
                item-text="userName"
                item-value="userName"
                :items="coachArray"
                style="width:100%;"
                class="ml-4"                                        
                label='Coach'
                :append-icon="'icons8-search'"
                clearable
                v-model="searchCoach"/>
        </div>
    
        <!--Table-->
        <v-data-table v-if="$vuetify.breakpoint.width >= 600"
            class="rounded-lg mt-4"
            :headers="computedHeaders"
            :items="computedTableData"
            item-key="id"
            :items-per-page="MIX_itemsPerPage(MIX_breakpoint())"
            :search="filter"
            >

            <!--Session Date-->
            <template v-slot:[`item.sessionDate`]="{ item }">
                <app-text category="text-small">{{dayTimeFormat(item.sessionDate, 'long')}}</app-text>
            </template>
            <!--Session Date-->
            <template v-slot:[`item.sessionCoach`]="{ item }">
                <app-text category="text-small">{{item.sessionCoach}}</app-text>
            </template>
            <!--Session Duration-->
            <template v-slot:[`item.sessionDuration`]="{ item }">
                <app-text category="text-small">{{ item.sessionDuration}} mins</app-text>
            </template>
            <!--PLayer Name-->
            <template v-slot:[`item.userName`]="{ item }">
                <app-text category="text-small">{{ item.userName}} </app-text>
            </template>
            <!--Current Position-->
            <template v-slot:[`item.currentPosition`]="{ item }">
                <app-text category="text-small">{{ item.currentPosition}} </app-text>
            </template>
            <!--Session Skill-->
            <template v-slot:[`item.sessionSkill`]="{ item }">
                <app-text category="text-small">{{ item.sessionSkill}} </app-text>
            </template>
            <!--Session Feeling-->
            <template v-slot:[`item.sessionFeeling`]="{ item }">
                <div>
                    <v-icon v-if="item.sessionFeeling === 'sad'" class="icons8-sad red--text"/>
                    <v-icon v-if="item.sessionFeeling === 'neutral'" class="icons8-neutral secondary--text"/>
                    <v-icon v-if="item.sessionFeeling === 'happy'" class="icons8-happy update--text"/>
                </div>
            </template>
            <!--Actions-->
            <template v-slot:[`item.actions`]="{item}">
                <v-btn class="primary--text white"
                    depressed @click="openRightPanel(item)">Open
                    <v-icon class="icons8-forward pa-0" color="primary"/>
                </v-btn>
            </template>
        </v-data-table>

        <!--Mobile Cards-->
        <div v-if="$vuetify.breakpoint.width < 600">
            <!--Session Card and Details-->
            <v-card  class="my-4 mt-4 pa-4 align-center full-width" @click="openRightPanel(item)" elevation="0" v-for="item in computedTableData" :key="item.id">                     
                <div class="full-width">
                    <v-row >
                        <v-col cols="12" xs="12" class="d-flex align-center full-width">
                            <v-row>
                                <v-row class="ma-0 pa-0"  style="cursor: pointer">
                                    <!-- Session Date-->
                                    <v-col cols="4" xs="4" style="border-right: 1px solid lightgrey" class="d-flex align-center">
                                        <div class="text-center"><v-icon class="secondary--text">icons8-calendar-2</v-icon></div>
                                        <app-text category="text-small" class="ml-2 grey--text">{{dayTimeFormat(item.sessionDate, 'short')}}</app-text>
                                    </v-col>
                                    <!-- Session Duration-->
                                    <v-col cols="4" xs="4" style="border-right: 1px solid lightgrey" class="d-flex align-center justify-center">
                                        <div class="text-center"><v-icon size="32" class="secondary--text">icons8-stopwatch-2</v-icon></div>
                                        <app-text category="text-small" class="ml-2 grey--text">{{item.sessionDuration}} </app-text>
                                    </v-col>
                                    <!-- Session Rating-->
                                    <v-col cols="4" xs="4" class="d-flex align-center justify-center">
                                        <div class="text-center"><v-icon size="32" class="secondary--text">icons8-star</v-icon></div>
                                        <app-text category="text-small" class="ml-2 grey--text">{{item.sessionRating}}/10</app-text>
                                    </v-col>
                                </v-row>
                                <!--Sessions Skills-->
                                <v-col cols="12" xs="12">
                                    <app-text category="text-medium-bold" class="primary--text">{{item.sessionSkill}}</app-text>
                                    <v-divider class="mt-2 mb-1"/>
                                </v-col>
                                <!--cHAT LAST MESSAGE-->
                                <v-col cols="12" xs="12" style="cursor: pointer">
                                    <div class="text--secondary d-flex align-center">
                                        <div class="text-center">
                                            <app-icon size="48" color="secondary" class="mr-4"
                                            :icon="currUserData.userName === item.sessionComments[item.sessionComments.length -1].user ? 'whistle' : 'users'">
                                            </app-icon>
                                        </div>
                                        <div >
                                            <span style="text-size-adjust:50%" class="caption font-weight-bold">{{dayTimeMinFormat(item.sessionCreatedAt)}}</span><br/>
                                            {{returnSubstring(item.sessionComments[item.sessionComments.length -1].content)}}
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>											
                        </v-col>
                    </v-row>
                </div>
            </v-card>

            <!--Load more button-->
            <div cols="12" xs="12" class="d-flex mt-5 align-center justify-center full-width" v-if="computedLoadButton">
                <div class=""><v-btn width="150" depressed large class="primary" @click="loadMore()">Load More</v-btn></div>
            </div>
        </div>
    </div>        
</template>

<script>
    import {mapGetters} from "vuex";
 
    export default {

        name: "squadSessions",
        props: ['usersData', 'filter', 'isRightPanelOpenSession', 'value'],

        data: () => ({

            sessionsArray : [],
            reasasibleSessionArray :[],
            skillsArray:[],
            coachArray:[],

            //filters
            searchCoach:'',
            searchName:'',
            searchPosition:'',
            searchDate:'',
            searchSkill:'',

            showMoreButton: true,           
            isPageLoading: true,
            
            currUserData:"",
            selectedUser:'',
            search:"",

             // Table headers
            headers: [
                { text: "Player Name", value: "userName", align: "left", sortable: false, hidden: false, hide: false,},
                { text: "Player Position", value: "currentPosition", align: "left", sortable: false, hidden: false, hide: false,},
                { text: "Duration", value: "sessionDuration", align: "left", sortable: false, hidden: true, hide: false,},
                { text: "Skill", value: "sessionSkill", align: "left",sortable: false, hidden: false, hide: false,},
                { text: "Date", value: "sessionDate", align: "left", sortable: false, hidden: false, hide: false,},
                { text: "Coach", value: "sessionCoach", align: "left", sortable: false, hidden: false, hide: false,},
                { text: "", value: "actions", align: "right", sortable: false,hasAccess: ['Staff-Admin','Player-User','Coach-User'], hidden: false, hide: false,width: "100px",},
            ],
        }),

        computed: {
            ...mapGetters({
                GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
            }),
            /**
             * Computed Table Data
             *
             * Return a sorted array of JSON objects.
             *
             * @returns {Array} Array of processed JSON data
             */
            computedTableData() {
                const t = this
                let tableData = t.sessionsArray
                if(t.searchName){
                    tableData = tableData.filter(data =>
                      data.userName.toUpperCase().match(t.searchName.toLocaleUpperCase()))
                }
                if(t.searchCoach){
                    tableData = tableData.filter(data => 
                        data.sessionCoach === t.searchCoach)
                }
                if(t.searchPosition){
                    tableData = tableData.filter(data =>
                      data.currentPosition.toUpperCase().match(t.searchPosition.toLocaleUpperCase()))
                }
                if(t.searchSkill){
                    tableData = tableData.filter(data =>
                      data.sessionSkill.toUpperCase().match(t.searchSkill.toLocaleUpperCase()))
                }
                if(t.searchDate){
                    tableData = tableData.filter(data =>
                    // t.$moment(Number(data.sessionDate)).format('dddd Do MMMM \'YY').toUpperCase().match(t.searchDate.toUpperCase()))
                    this.$moment(data.sessionDate, 'X').format('dddd Do MMMM \'YY').toUpperCase().match(t.searchDate.toUpperCase()))
                }
                // Sort by creation date
                tableData = tableData.sort((a, b) => {
                    return b.sessionDate > a.sessionDate ? 1 : -1
                })
                return tableData
            },
            /**
             * Computed Headers
             *
             * Only display headers that are not set to hidden and the user has access to (via userLevel).
             *
             * @returns {[headers]} - filtered headers to render in the table
             */
            computedHeaders() {
                const t = this
                const headers = t.headers
                let filteredHeaders = []
                headers.forEach(header => {
                    // Only Admins can view the OPEN button
                    if (header.value === 'actions' && !header.hasAccess.includes(t.currUserData.userLevel)) {
                        return
                    }
                    // If the column is not hidden, show it
                    if (!header.hidden) {
                        filteredHeaders.push(header)
                    }
                })
                return filteredHeaders
            },
            /**
             * This function detects if the matches of the user are more than 4 if yes then shows the load more button
             */
            computedLoadButton(){
                if(this.reasasibleSessionArray.length >4 && this.sessionsArray.length !=this.reasasibleSessionArray.length){
                    return true;
                }else{
                    return false;
                }
            },
            sortPositions(){
                return this.playersPositions.sort()
            }
        },
        methods: {
            returnSubstring(text){
                return text.substring(0,30)+".."
            },
             /**
             * This method return the date in a nice format
             * @return The date
             */
            dayTimeFormat(date, shortLong){
                let formatedDate ="";
                if( shortLong === "short"){
                    formatedDate = this.$moment(date, 'X').format('DD MMM YY');
                }else if(shortLong === 'long'){
                    formatedDate = this.$moment(date, 'X').format('dddd Do MMMM \'YY');
                }
               return formatedDate
            },
            /**
             * This method return the date of the last message in a nice format
             * @return The date of the last message
             */
            dayTimeMinFormat(item){
                return this.$moment(item, 'X').format('DD MMM YY | HH:mm');
            },
            /**
             * This method detects if there are more mattches to be show if yes 
             * load those matches and display them
             */
            loadMore(){
                let remainSessions = this.sessionsArray.length - this.reasasibleSessionArray.length;
                if(remainSessions > 0 && remainSessions> 5){
                    for (var i=0; i<5; i++){
                        this.reasasibleSessionArray.push(this.sessionsArray[this.reasasibleSessionArray+i])
                    }
                }else if(remainSessions <= 0 ){
                    this.showMoreButton = false;
                }else{
                    this.reasasibleSessionArray = this.sessionsArray;
                    this.showMoreButton = false;
                }
            },
            /**
             * lIMITS the possible matches reviews available
             */
            limitSessionView(){
                if(this.sessionsArray.length > 5){
                    for (var i=0; i<5; i++){
                        this.reasasibleSessionArray.push(this.sessionsArray[i])
                    }
                }else{
                    this.reasasibleSessionArray = this.sessionsArray
                }
            },          
            /**
             * Open Right Panel
             * Open the CRUD form.
             * If the form is opened using the new button, it will be blank.
             * If the form is opened from the table, it will be assigned here and populated.
             * @param item JSON containing the selected data, if required
             */
            async openRightPanel(item) {
                this.$emit('rightPanel',this.event);
                this.$emit("selectedSession", item);
            },
            /**
             * Reads and sets User data
             */
            async readUserData(){
                let userDataResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, 'users');
                this.currUserData = userDataResult.data;
            },
            /**
             * Read the sessions that corrispond to the user
             */
            async readSessions(){
                this.sessionsArray = await this.MIX_firestore_allData('sessions')
                this.limitSessionView();
            },
            async readSkills(){
                this.skillsArray = await this.MIX_firestore_allData('skills');
                this.skillsArray =  this.$sortBy(this.skillsArray, 'name')
            },

 
             async findCoaches(){
                let coaches = await this.MIX_firestore_readManyWhereCondition("users", "userLevel", "!=", "Player-User")

                coaches.forEach( coach => {
                    var regExp = new RegExp("[a-z0-9\.-_]*@vindico\.net$" , "i");
                    var regExp1 = new RegExp("[a-z0-9\.-_]*@admin\.com$" , "i");

                    if ( !coach.userEmail.match(regExp) && !coach.userEmail.match(regExp1)){
                        this.coachArray.push(coach)
                    }
                }) 
                    
                this.coachArray = this.$sortBy(this.coachArray, 'userName');
            }
        },
        async created(){
            this.readUserData();
            this.readSessions();
            this.readSkills();
            this.findCoaches();
            this.isPageLoading=false;
        }
    }
</script>

<style scoped>
   
</style>
