<template>
    <v-row no-gutters>

        <!--Page Loader-->
        <pageLoader :is-page-loading="isPageLoading"/>

        <!--Page Content-->
        <v-row v-if="!computedIsPageLoading" no-gutters style="height: 100%">

            <!--Left Panel-->
            <v-col v-if="computedSplitScreenColumns.left"
                   :cols="computedSplitScreenColumns.left"
                  >
            <div class="pa-4">
                <!--Page Title-->
                <div class="">
                    <page-title icon="icons8-user-2" pageTitle="Users" :divider="true" 
                        :description="['Staff-Admin'].includes(currUserData.userLevel)? 'View and update user records, and update their access and permissions' 
                         : 'View users on the system'
                        "/>
                </div>

                <!--Filters-->
                <app-input v-if="$vuetify.breakpoint.width <= 700" input-type="textInput" 
                    append-icon="icons8-search"
                    label="Search"
                    placeholder='Search user by name and email'
                    v-model.trim="search"/>

                <div class="d-flex" v-if="$vuetify.breakpoint.width >= 700" style="width:100%">
                    <app-input input-type="textInput"
                        style="width:100%;"
                        label="Name"
                        :append-icon="'icons8-search'"
                        clearable
                        v-model.trim="searchName"/>
                   
                    <app-input v-if="$vuetify.breakpoint.width > 600" input-type="select"
                        :items="sortFieldPositions"
                        style="width:100%;"
                        class="ml-4"                                        
                        label='Field Position'
                        :append-icon="'icons8-search'"
                        clearable
                        v-model="searchFieldPosition"/>

                     <app-input v-if="$vuetify.breakpoint.width > 600" input-type="select"
                        :items="sortPositions"
                        style="width:100%;"
                        class="ml-4"                                        
                        label="Position"
                        :append-icon="'icons8-search'"
                        clearable
                        v-model="searchPosition"/>


                    <app-input v-if="$vuetify.breakpoint.width > 600" input-type="select"
                        :items="sortUserLevel"
                        style="width:100%;"
                        class="ml-4"                                        
                        label='User Level'
                        :append-icon="'icons8-search'"
                        clearable
                        v-model="searchUserLevel"/>
                </div>

                 <!--Filter buttons | Action buttons-->
                <div v-if="['Staff-Admin'].includes(currUserData.userLevel)" class="" dense>

                    <!--Toggle filters | Action buttons-->
                    <div class="d-flex justify-space-between mt-4  mb-4" >

                    <!--Toggle buttons-->
                    <v-btn-toggle v-model="filterByStatus" 
                        multiple 
                        color="white" 
                        :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width:50%'"
                        class="rounded-lg "
                        active-class="white" 
                        background-color="appBackground" 
                        dense>

                        <!--Pending-->
                        <v-btn :style="$vuetify.breakpoint.width < 600 && 'width: 50%'"
                            value="PENDING" 
                            large 
                            :height="buttonSizeDefault">
                            <v-icon color="error" size="24" class="icons8-inactive-state mr-1 "/>
                            <app-text category="text-small" class="black--text" >
                                Pending
                            </app-text>
                        </v-btn>

                        <!--Approved-->
                        <v-btn value="APPROVED" 
                        :style="$vuetify.breakpoint.width < 600 && 'width: 50%'"
                        large :height="buttonSizeDefault">
                            <v-icon color="success" class="icons8-ok mr-1"/>
                            <app-text category="text-small" class="black--text">
                                Approved
                            </app-text>
                        </v-btn>

                        <!--Rejected-->
                        <v-btn  v-if="$vuetify.breakpoint.width >= 600" value="REJECTED" large :height="buttonSizeDefault">
                            <v-icon color="darkgrey" class="icons8-cancel mr-1"/>
                            <app-text category="text-small" class="black--text">
                                Rejected
                            </app-text>
                        </v-btn>

                        <!--Suspended-->
                        <v-btn   v-if="$vuetify.breakpoint.width >= 600" value="SUSPENDED" large :height="buttonSizeDefault">
                            <v-icon color="error" class="icons8-private-2 mr-1"/>
                            <app-text category="text-small" class="black--text" >
                                        Suspended 
                            </app-text>
                        </v-btn>

                        <!--Archived-->
                        <v-btn v-if="$vuetify.breakpoint.width >= 600" value="ARCHIVED" large :height="buttonSizeDefault">
                            <v-icon color="grey" class="icons8-xbox-a mr-1"/>
                            <app-text category="text-small" class="black--text" >
                                Archived
                            </app-text>
                        </v-btn>
                    </v-btn-toggle>

                    <app-btn v-if="$vuetify.breakpoint.width >= 600 && !computedSplitScreenColumns.right"
                            @click.native="MIX_exportDocuments(headersCSV,'Staff Directory',formatExport)"
                            class="ml-4"
                            color="primary"
                            hide-label-below="810"
                            icon="icons8-export-csv"
                            label="Export"/>

                    </div>
                </div>
                <!--Table-->
                <v-data-table v-if="$vuetify.breakpoint.width > 700"
                    class="rounded-lg mt-4"
                    :headers="computedHeaders"
                    :items="computedTableData"
                    item-key="id"
                    :items-per-page="MIX_itemsPerPage(MIX_breakpoint())"
                    :search="search"
                    sort-by="userName">

                    <!--Id-->
                    <template v-slot:[`item.id`]="{ item }">
                        <app-text category="text-small">{{ item.id }}</app-text>
                    </template>

                    <!--User Status-->
                    <template v-slot:[`item.userStatus`]="{ item }">
                        <div>
                            <v-icon v-if="item.userStatus === 'Pending'" class="icons8-inactive-state" color="error"/>
                            <v-icon v-if="item.userStatus === 'Rejected'" class="icons8-cancel" color="orange"/>
                            <v-icon v-if="item.userStatus === 'Approved'" class="icons8-checkmark-yes" color="success"/>
                            <v-icon v-if="item.userStatus === 'Suspended'" class="icons8-private-2" color="error"/>
                            <v-icon v-if="item.userStatus === 'Archived'" class="icons8-xbox-a" color="grey"/>
                        </div>
                    </template>
                    <!--User Profile-->
                    <template v-slot:[`item.userProfile`]="{ item }">
                        <v-img v-if="item.userProfile!=''" :src="item.userProfile"
                            class="rounded-lg mt-2 mb-2" cover max-height="48" max-width="48" style="aspect-ratio:1/1" />
                        <v-icon v-else class="icons8-user-2 mt-2 mb-2" color="secondary mt-2 mb-2" size="48" />
                    </template>
                    <!--User Name-->
                    <template v-slot:[`item.userName`]="{ item }">
                        <app-text category="text-small">{{ item.userName }}</app-text>
                    </template>
                    <!--ForwardBack-->
                    <template v-slot:[`item.userForwardBack`]="{ item }">
                        <app-text category="text-small">{{ item.userForwardBack }}</app-text>
                    </template>
                     <!--Player Positions-->
                    <template v-slot:[`item.userPlayerPosition`]="{ item }">
                        <app-text v-if="item.userPlayerPosition != ''" category="text-small">{{ item.userPlayerPosition }}</app-text>
                        <app-text v-else category="text-small"> N/A</app-text>
                    </template>
                    <!--User Level-->
                     <template v-slot:[`item.userLevel`]="{ item }">
                        <app-text category="text-small">{{ item.userLevel }}</app-text>
                    </template>
                    <!--Actions-->
                    <template v-slot:[`item.actions`]="{item}">
                        <v-btn class="primary--text white"
                            depressed @click="openRightPanel(item)">Open
                            <v-icon class="icons8-forward pa-0" color="primary"/>
                        </v-btn>
                    </template>
                </v-data-table>

                <!-- Mobile Cards -->
                <div v-if="$vuetify.breakpoint.width <= 700" class="">
                    <v-card v-for="item in computedTableData" :key="item.id" flat
                        @click="openRightPanel(item)"
                        class="rounded-lg mt-4" >
                        <!--Job Title-->
                        <div class="d-flex align-center mb-2 pa-4">
                             <v-img v-if="item.userProfile!=''" :src="item.userProfile" style="aspect-ratio:1/1" 
                               class="rounded-lg " cover max-height="80" max-width="80" />
                            <v-icon v-else class="icons8-user-2" color="secondary" size="80"/>
                            <div class="d-flex flex-column ml-4">
                                <app-text category="text-default-bold" class="">{{ item.userName }}</app-text>
                                <app-text category="text-small" class="grey--text">{{ item.userEmail }}</app-text>
                            </div>
                        </div>
                    </v-card> 
                </div>
            </div>
            </v-col>

            <!--Right panel-->
            <v-col v-if="isRightPanelOpen"
                :class="computedSplitScreenColumns.left && 'borderLeft'"
                :cols="computedSplitScreenColumns.right">

                <!--Action Bar-->
                <div class="d-flex align-center justify-space-between appbackground pa-4">
                    <!--Title-->
                    <app-text category="text-medium">
                        {{ selectedItem && selectedItem.userName ? 'Edit' : 'New' }}
                    </app-text>
                    <v-spacer/>
                    <!--Approve-->
                    <appbutton v-if="selectedItem.userStatus === 'Pending'"
                        label="Approve" icon="icons8-ok" type="success" class="ma-0" showlabel="mdAndUp"
                        @click.native="awaitingApproval = true;  status='Approved'"/>

                    <!--Reject-->
                    <appbutton v-if="selectedItem.userStatus === 'Pending'"
                        label="Reject" icon="icons8-cancel" type="warning" class="ma-0"
                        showlabel="mdAndUp" @click.native="awaitingApproval = true; status='Rejected'"/>
                    
                    <div>
                        <!--Edit Icon-->
                        <edit-icon :isActive="!isFormReadOnly" @click.native="editForm"/>
                        <!--Close Icon-->
                        <close-icon @click.native="closeRightPanel"/>
                    </div>
                </div>
                <!--Tabs (Overview)-->
                <v-tabs v-model="tabs" show-arrows dark background-color="primary">
                    <v-tabs-slider color="secondary"/>
                    <v-tab href="#overview">
                        <app-text category="text-small">Overview</app-text>
                    </v-tab>
                </v-tabs>
                <!--Tab Content-->
                <v-tabs-items v-model="tabs">
                    <!--Overview Tab Content-->
                    <v-tab-item class="appBackground pa-4 " value="overview">
                        <user-form :doesFormStartReadOnly="isFormReadOnly"
                            :formData="selectedItem"
                            v-on:emitCloseRightPanel="emittedCloseRightPanel"/>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <!--Dialog-->
        <v-dialog style="z-index: 3000 !important;" v-model="awaitingApproval" persistent max-width="512">
            <v-card class="white">
                <v-card-title  class="primary white--text">Awaiting Approval</v-card-title>
                <v-card-text class="mt-4">        
                    <app-text v-if="status=='Approved'" category="text-medium" >
                        You are about to approve the account for {{selectedItem.userName}}. This will grant them access to the app, and also set the permissions they will have for the role they're assigned to.
                    </app-text> 
                    <app-text v-else category="text-medium" >You are about to reject the account for {{selectedItem.userName}}. 
                        This will deny them access to the app so they cannot log in.
                    </app-text>
                    <app-text v-if="status=='Approved'" category="text-medium-bold" class="mt-4">Please choose a level from the list below.</app-text>
                    <app-text v-else category="text-medium-bold" class="mt-4">Are you sure you want to do this?</app-text>
                    <!--User role-->
                    <v-col v-if="status=='Approved'" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <app-input input-type="select"
                            :class="{'px-1 mt-4': $vuetify.breakpoint.smAndUp, 'mt-4': $vuetify.breakpoint.xsOnly}"
                            :items="userLevels"
                            label="User Level"
                            v-model="approvalUserLevel"
                            required="userLevelRules">
                            <template v-slot:item="data">
                                <app-text category="text-default">{{ data.item }}</app-text>
                            </template>
                        </app-input>
                    </v-col>
                </v-card-text>
                <v-card-actions class="text-center">
                    <!--Dialog Actions--> 
                    <v-row class="ma-0 pa-0" justify="center">
                        <v-col cols="6">
                            <app-btn @click.native="noAction()" color="grey" icon="icons8-cancel" block label="No"/>                
                        </v-col>
                        <v-col cols="6">
                            <app-btn block @click.native="yesAction()" color="success" :icon="status === 'Approved' ? 'icons8-save':'icons8-ok'" :label="status === 'Approved' ? 'Save':'Yes'"/>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import UserForm from "@/views/users/userForm/userForm";
    import {mapGetters} from "vuex";

    export default {

        name: "users",

        components: {UserForm},

        data: () => ({
            userLevels: ['Coach-User', 'Player-User', 'Staff-Admin'],
            status:'',
            awaitingApproval: false,
            searchName: '',
            approvalUserLevel:'',
            isRightPanelOpen: false,
            isFormReadOnly: true,
            selectedItem: {},
            filter:'',
            filterByStatus:['PENDING', 'APPROVED'],

            // Table headers
            headers: [
                { text: "ID", value: "id", align: "start", sortable: false, hidden: true, hide: false, hasAccess: ['Staff-Admin']},
                { text: "Status", value: "userStatus", align: "center", sortable: false, hidden: false, hide: false, width: '69px',},
                { text: "", value: "userProfile", align: "start",sortable: false, hidden: false, hide: false,},
                { text: "Name", value: "userName", align: "start",sortable: false, hidden: false, hide: false,},
                { text: "ForwardBack", value: "userForwardBack", align: "start", sortable: false, hidden: false,hide: true,},
                { text: "Player Position",value: "userPlayerPosition", align: "start", sortable: false, hidden: false, hide: true,},
                { text: "User Level",value: "userLevel", align: "start", sortable: false, hidden: false, hide: true,},
                { text: "", value: "actions", align: "right", sortable: false,hasAccess: ['Staff-Admin'], hidden: false, hide: false,width: "100px",},
            ],

            // Header CSV for export
            headersCSV: {
                userName: 'User Name',
                userTelephone: 'User Telephone',
                userEmail: 'User Email',
                userForwardBack: 'Field Position',
                userPlayerPosition: 'Player Position',
                userLevel: 'User Level',
                userStatus: 'Access',
            },

            search: "", // Table search,
            searchFieldPosition: "",
            searchPosition: '',
            searchUserLevel:'',
            tableData: [],
            tabs: '',

            // Collection Data
            usersCollectionData: [],
            isusersCollectionDataLoaded: false,
            isPageLoading: true,
            userLevelRules: [(v) => !!v || "User Level is required"],
        }),

        computed: {
            sortFieldPositions(){
                return this.forwardBackArray.sort()
            },
            sortUserLevel(){
                return this.userLevelsOptionsData.sort()
            },
             sortPositions(){
                return this.playersPositions.sort()
            },
            ...mapGetters({ 
                GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
            }),
            //Format of the Export File
            formatExport() {
                const t = this
                let user = JSON.parse(JSON.stringify(t.computedTableData));
                t.exportStaff = [];
                for (let i = 0; i < user.length; i++) {
                    
                    let userName = "";
                    if (user[i].userName) {
                        userName = user[i].userName;
                    } else {
                        userName = "UNKNOWN"
                    }
                    let userTelephone = "";
                    if (user[i].userTelephone) {
                        userTelephone = user[i].userTelephone;
                    } else {
                        userTelephone = "N/A"
                    }
                    let userEmail = "";
                    if (user[i].userEmail) {
                        userEmail = user[i].userEmail;
                    } else {
                        userEmail = "UNKNOWN"
                    }

                     let userForwardBack = "";
                    if (user[i].userForwardBack) {
                        userForwardBack = user[i].userForwardBack;
                    } else {
                        userForwardBack = "N/A"
                    }

                    let userPlayerPosition = "";
                    if (user[i].userPlayerPosition) {
                        userPlayerPosition = user[i].userPlayerPosition;
                    } else {
                        userPlayerPosition = "N/A"
                    }
                    let userLevel = "";
                    if (user[i].userLevel) {
                        userLevel = user[i].userLevel;
                    } else {
                        userLevel = "UNKNOWN"
                    }
                    let userStatus = "";
                    if (user[i].userStatus) {
                        userStatus = user[i].userStatus;
                    } else {
                        userStatus = "UNKNOWN"
                    }
                    t.exportStaff.push({
                        userName: userName,
                        userTelephone: userTelephone,
                        userEmail: userEmail,
                        userForwardBack: userForwardBack,
                        userPlayerPosition: userPlayerPosition,
                        userLevel: userLevel,
                        userStatus: userStatus,
                    })
                }
                return t.exportStaff;
            },
            /**
             * Computed Is Page Loading
             *
             * Return a boolean for the page loading spinner to denote if all data has been loaded.
             *
             * @returns {boolean} if data has been loaded or not
             */
            computedIsPageLoading() {
                const t = this
                t.isPageLoading = !(
                    t.isusersCollectionDataLoaded
                )
                return t.isPageLoading
            },
            /**
             * Computed Split Screen Columns
             *
             * Calculate the width of the left snd right panels, based on the currently selected functionality.
             * The left panel has the table, the right panel has the forms.
             *
             * @returns {{left: number, right: number}}
             */
            computedSplitScreenColumns() {
                const t = this
                let left = 12
                let right = 0
                if (t.$vuetify.breakpoint.width >= 1200) {
                    if (t.isRightPanelOpen) {
                        left = 6
                        right = 6
                    }
                } else {
                    if (t.isRightPanelOpen) {
                        left = 0
                        right = 12
                    }
                }
                return {left, right}
            },
            /**
             * Computed Headers
             *
             * Only display headers that are not set to hidden and the user has access to (via userLevel).
             *
             * @returns {[headers]} - filtered headers to render in the table
             */
            computedHeaders() {
                const t = this
                const headers = t.headers
                let filteredHeaders = []
                headers.forEach(header => {
                    // Only Admins can view the OPEN button
                    if (header.value === 'actions' && !header.hasAccess.includes(t.currUserData.userLevel)) {
                        return
                    }
                    // If the column is not hidden, show it
                    if (!header.hidden) {
                        filteredHeaders.push(header)
                    }
                })
                return filteredHeaders
            },
            /**
             * Computed Table Headers
             *
             * Using the tableHeaders data, hide the fields that aren't required.
             *
             * @returns {Array} an array of table header objects
             */
            computedTableHeaders() {
                const t = this
                let tableHeaders = t.tableHeaders
                tableHeaders = tableHeaders.filter(header => !header.hiddenByDefault)
                if (t.computedSplitScreenColumns.left < 12) {
                    tableHeaders = tableHeaders.filter(header => !header.hideWithWidth)
                }
                return tableHeaders
            },
            /**
             * Computed Table Data
             *
             * Return a sorted array of JSON objects.
             *
             * @returns {Array} Array of processed JSON data
             */
            computedTableData() {
                const t = this
                let tableData = t.usersCollectionData
                // Filter by user Name
                if (t.searchName) {
                    tableData = tableData.filter(data =>
                        data.userName.toUpperCase().match(t.searchName.toLocaleUpperCase()))
                }
                //Filter By field Position
                if (t.searchFieldPosition) {
                    tableData = tableData.filter(data =>
                        data.userForwardBack.toUpperCase().match(t.searchFieldPosition.toLocaleUpperCase()))
                }

                //Search By player position
                if (t.searchPosition) {
                    tableData = tableData.filter(data =>
                        data.userPlayerPosition.toUpperCase().match(t.searchPosition.toLocaleUpperCase()))
                }

                //Search By userLevel
                if (t.searchUserLevel) {
                    tableData = tableData.filter(data =>
                        data.userLevel.toUpperCase().match(t.searchUserLevel.toLocaleUpperCase()))
                }


                if(t.filterByStatus.length !=0){
                    tableData = tableData.filter(function (item) {
                        return t.filterByStatus.includes(item.userStatus.toLocaleUpperCase())
                    })
                }
                if(t.$vuetify.breakpoint.width <= 700 && t.search){
                    tableData = tableData.filter(data =>
                        data.userName.toUpperCase().match(t.search.toLocaleUpperCase()) ||
                        data.userEmail.toUpperCase().match(t.search.toLocaleUpperCase()))
                }
                // Sort alphabetically
                tableData = tableData.sort((a, b) => {
                    return a.userName > b.userName ? 1 : -1
                })
                return tableData
            },
        },
        methods: {
            // * The dialog option NO
            noAction(){
            this.awaitingApproval = false;
            },
            async yesAction(){
                if(this.approvalUserLevel !='' &&this.status==='Approved'){
                    this.selectedItem.userStatus =this.status
                    this.selectedItem.userLevel = this.approvalUserLevel;
                    await this.MIX_firestore_updateMultipleFields(this.selectedItem.id, 'users', this.selectedItem)
                    this.awaitingApproval = false;
                    this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'User has been approved', show: true })
                    this.closeRightPanel();
                }else if( this.status ==='Rejected'){
                    this.selectedItem.userStatus =this.status
                    await this.MIX_firestore_updateMultipleFields(this.selectedItem.id, 'users', this.selectedItem)
                    this.awaitingApproval = false;
                    this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'User has been rejected', show: true })
                    this.closeRightPanel();
                } else{
                    this.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Please select permissions', show: true })
                }
            },
            /**
             * Get Collection Data
             *
             * Fetch the data from the collection and assign them to the class state.
             *
             * @returns {Promise<void>}
             */
            async getUsersCollectionData() {
                const t = this
                let usersCollectionData = []
                let collection = await this.MIX_firestore_allData('users')
                t.usersCollectionData = collection;
                t.isusersCollectionDataLoaded = true
            },
            /**
             * Close Right Panel
             *
             * When closing the form, reset it to ensure it opens as new.
             */
            closeRightPanel() {
                const t = this
                t.isFormReadOnly = true
                t.isRightPanelOpen = false
            },
            /**
             * Edit Form
             *
             * Toggle the read only state of the form.
             */
            editForm() {
                const t = this
                t.isFormReadOnly = !t.isFormReadOnly;
            },
            /**
             * Emitted Close Right Panel
             *
             * Take the emitted message and call to close the right panel.
             *
             * @returns {Promise<void>}
             */
            async emittedCloseRightPanel() {
                const t = this
                t.getUsersCollectionData();
                t.closeRightPanel()
            },
            /**
             * Emitted Delete From Dialog
             *
             * Take the emitted message from the 'deleteIconDialog', and mark the selected document as deleted.
             *
             * @returns {Promise<void>}
             */
            async emittedDeleteFromDialog() {
                const t = this
                const deleteDocument = await t.MIX_deleteDocument('users', t.selectedItem.id)
                t.closeRightPanel()
                t.MIX_renderConfirmationAlert(deleteDocument,'Successfully Deleted user', 'Error Deleting user')
            },
            /**
             * Open Right Panel
             *
             * Open the CRUD form.
             * If the form is opened using the new button, it will be blank.
             * If the form is opened from the table, it will be assigned here and populated.
             *
             * @param item JSON containing the selected data, if required
             */
            openRightPanel(item) {

                if( this.currUserData.userLevel === 'Staff-Admin'){
                     const t = this
                    if (!item?.id) t.isFormReadOnly = false
                    t.selectedItem = item
                    t.isRightPanelOpen = true
                }else{
                    this.isRightPanelOpen = false
                }
                // v-if="['Player-User'].includes(userDataResult.userLevel)" 

               
            },
        },
        async mounted() {
            const t = this
            await t.getUsersCollectionData()
        },
        async created(){
            let userDataResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, 'users');
            this.currUserData = userDataResult.data;
        }
    }
</script>

<style scoped>
    .borderLeft {
        border-left: 4px solid #999999;
        min-height: calc(100vh - 128px);
    }
</style>
