<template>
    <v-row no-gutters>

        <!--Page Loader-->
        <pageLoader :is-page-loading="isPageLoading"/>

        <!--Page Content--> 
        <v-row  no-gutters style="height: 100%">

            <!--Left Panel-->
            <v-col v-if="computedSplitScreenColumns.left" :cols="computedSplitScreenColumns.left" class="">
               	<div class="pa-4">

                    <!--Page Title-->
                    <div class="d-flex justify-space-between align-start">
                        <page-title :divider="true" icon="icons8-rugby-3" pageTitle="Matches" description="Log and view matches, and also feedback from your coach"/>
                        <app-icon icon="plus" color="secondary" size="48" @click.native="openRightPanel('')" />
                    </div>
 
                    <!--Filters-->
                    <div class="d-flex">
                        <app-input v-if="$vuetify.breakpoint.width <= 700" input-type="textInput" 
                            append-icon="icons8-search"
                            label="Search"
                            placeholder='Search by match opponent, performance'
                            v-model.trim="search"/>
                        
                        <app-input v-if="$vuetify.breakpoint.width > 700" input-type="textInput"
                            style="width:100%;"
                            label= 'Date'
                            clearable
                            :append-icon="'icons8-search'"
                            v-model.trim="searchDate"/>
                        
                        <app-input v-if="$vuetify.breakpoint.width > 700" input-type="textInput"
                            style="width:100%;"
                            class="ml-4"       
                            clearable                     
                            label='Opponent'
                            :append-icon="'icons8-search'"
                            v-model.trim="searchOpponent"/>

                        <app-input v-if="$vuetify.breakpoint.width > 700" input-type="select"
                            :items="sortPositions"
                            style="width:100%;"
                            class="ml-4"              
                            clearable              
                            label='Position'
                            :append-icon="'icons8-search'"
                            v-model="searchPosition"/>
                        
                    </div>

                    <!--Table-->
                    <v-data-table v-if="$vuetify.breakpoint.width >= 600"
                        class="rounded-lg mt-4"
                        :headers="computedHeaders"
                        :items="computedTableData"
                        item-key="id"
                        :items-per-page="MIX_itemsPerPage(MIX_breakpoint())"
                        :search="search"
                        sort-by="matchDate">

                        <!--match Date-->
                        <template v-slot:[`item.matchDate`]="{ item }">
                            <app-text category="text-small">{{dayTimeFormat(item.matchDate, 'long')}}</app-text>
                        </template>
                        <!--match opponent-->
                        <template v-slot:[`item.matchOpponent`]="{ item }">
                            <app-text category="text-small">{{ item.matchOpponent}} </app-text>
                        </template>
                        <!--match Duration-->
                        <template v-slot:[`item.matchDuration`]="{ item }">
                            <app-text category="text-small">{{ item.matchDuration}} mins</app-text>
                        </template>
                        <!--match performance-->
                        <template v-slot:[`item.matchPerformance`]="{ item }">
                            <app-text category="text-small">{{ item.matchPerformance}}</app-text>
                        </template>
                        <!--match fatigue-->
                        <template v-slot:[`item.matchFatigue`]="{ item }">
                            <app-text category="text-small">{{ item.matchFatigue}}</app-text>
                        </template>
                           <!--PLayer Name-->
                        <template v-slot:[`item.userName`]="{ item }">
                            <app-text category="text-small">{{ item.userName}} </app-text>
                        </template>
                        <!--Position-->
                        <template v-slot:[`item.matchPlayerPostition`]="{ item }">
                            <app-text category="text-small">{{ item.matchPlayerPostition}} </app-text>
                        </template>
                       
                        <!--match Feeling-->
                        <template v-slot:[`item.matchFeeling`]="{ item }">
                            <div>
                                <v-icon v-if="item.matchFeeling === 'sad'" class="icons8-sad red--text"/>
                                <v-icon v-if="item.matchFeeling === 'neutral'" class="icons8-neutral secondary--text"/>
                                <v-icon v-if="item.matchFeeling === 'happy'" class="icons8-happy update--text"/>
                            </div>
                        </template>
                        <!--Actions-->
                        <template v-slot:[`item.actions`]="{item}">
                            <v-btn 
                                class="primary--text white"
                                depressed 
                                @click="openRightPanel(item)">
                                Open <v-icon class="icons8-forward " color="primary"/>
                            </v-btn>
                        </template>
                    </v-data-table>

                    <!--Mobile-->
                    <div v-if="$vuetify.breakpoint.width < 600">

                        <!--Match Card And Details-->
                        <v-card class="my-4 pa-4 align-center" elevation="0" v-for="item in computedTableData" :key="item.id" @click="openRightPanel(item)">                
                            <div>
                                <v-row class="">
                                    <v-col cols="12" xs="12" class="d-flex align-center">
                                        <v-row>
                                            <v-row class="ma-0"  style="cursor: pointer">
                                                <!--Date-->
                                                <v-col cols="6" xs="6" style="border-right: 1px solid lightgrey" class="d-flex align-center">
                                                    <div class="text-center"><v-icon class="secondary--text">icons8-calendar-2</v-icon></div>
                                                    <app-text category="text-small" class="ml-2 grey--text">{{dayTimeFormat(item.matchDate, 'short')}}</app-text>
                                                </v-col>
                                                <!--Opponent -->
                                                <v-col cols="6" xs="6" class="d-flex align-center ">
                                                    <div class="text-center"><v-icon  class="secondary--text">icons8-rugby-3</v-icon></div>
                                                    <app-text category="text-small" class="ml-2 grey--text">{{item.matchOpponent}}</app-text>
                                                </v-col>
                                                <!--Fatigue-->
                                                <v-col cols="6" xs="6"  style="border-right: 1px solid lightgrey" class="mt-2 d-flex align-center">
                                                    <div class="text-center"><v-icon  class="secondary--text">icons8-sleep</v-icon></div>
                                                    <app-text category="text-small" class="ml-2 grey--text">{{item.matchFatigue}}</app-text>
                                                </v-col>
                                                <!--Performance-->
                                                <v-col cols="6" xs="6" class="d-flex align-center ">
                                                    <div class="text-center"><v-icon  class="secondary--text">icons8-speed</v-icon></div>
                                                    <app-text category="text-small" class="ml-2 grey--text">{{item.matchPerformance}}</app-text>
                                                </v-col>
                                                <!--Player Position-->
                                                <v-col cols="12" xs="12">
                                                    <app-text category="text-medium-bold" class="primary--text">{{item.matchPlayerPostition}}</app-text>
                                                    <v-divider class="mt-2 mb-2"/>
                                                </v-col>
                                            </v-row>
                                            <!--Chat Last message-->
                                            <v-col cols="12" xs="12" style="cursor: pointer">
                                                <div class="text--secondary d-flex align-center">
                                                    <div class="text-center">
                                                        <app-icon size="48" color="secondary" class="mr-4"
                                                        :icon="currUserData.userName === item.matchComments[item.matchComments.length -1].user ? 'users' : 'whistle'">
                                                        </app-icon>
                                                    </div>
                                                    <div >
                                                        <span style="text-size-adjust:50%" class="caption font-weight-bold">{{dayTimeMinFormat(item.matchComments[item.matchComments.length-1].created_at)}}</span><br/>
                                                        {{returnSubstring(item.matchComments[item.matchComments.length -1].content)}}
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>						
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>

                        <!--Load More Buttons-->
                        <div cols="12" xs="12" class="d-flex mt-5 align-center justify-center full-width" v-if="computedLoadButton">
                            <div class=""><v-btn width="150" depressed large class="primary" @click="loadMore()">Load More</v-btn></div>
                        </div>
                    </div>
                </div>
            </v-col>
            <!--End of Left Panel-->
           
            <!--Right panel-->
            <v-col v-if="isRightPanelOpen" :class="computedSplitScreenColumns.left && 'borderLeft'" :cols="computedSplitScreenColumns.right">
                
                <!--Action Bar-->
                <div class="d-flex align-center justify-space-between appbackground pa-4">

                    <!--Title-->
                    <v-icon class="mr-4" color="secondary" size="48">icons8-rugby-3</v-icon>
                    <app-text class="primary--text" category="text-large">
                        {{selectedItem && selectedItem.matchOpponent ? 'Edit Match' : 'New Matches'}}
                    </app-text>

                    <v-spacer/>

                    <!--Right Panel Buttons-->
                    <div>
                        <!--Edit Icon-->
                        <edit-icon v-if="selectedItem != '' && tabs!='feedback'" :isActive="!isFormReadOnly" @click.native="editForm"/>
                        <!-- Delete Icon -->
                        <delete-icon-dialog v-if="selectedItem != '' && tabs!='feedback'" :disabled="!isFormReadOnly" :item-to-delete="selectedItem && selectedItem.matchOpponent" v-on:emitDeleteFromDialog="emittedDeleteFromDialog"/>
                        <!--Close Icon-->
                        <close-icon @click.native="closeRightPanel"/>
                    </div>
                </div>

                 <!--Tabs (Overview)-->
                <v-tabs v-model="tabs" dark background-color="primary" show-arrows>
                    <v-tabs-slider color="secondary"/>
                    <v-tab href="#match">
                        <app-text category="text-small">Match</app-text>
                    </v-tab>
                    <v-tab v-if="selectedItem != ''" href="#feedback">
                        <app-text category="text-small">Comments</app-text>
                    </v-tab>
                </v-tabs>

                <!--Tab Content-->
                <v-tabs-items v-model="tabs">
                    <!--Overview Tab Content-->
                    <v-tab-item class="appBackground" value="match">
                        <match-form :doesFormStartReadOnly="isFormReadOnly" :formData="selectedItem" v-on:emitCloseRightPanel="emittedCloseRightPanel"/>
                    </v-tab-item>
                    <v-tab-item  class="appBackground" value="feedback">
                        <chat :chat="selectedItem.matchComments" :id="selectedItem.id" :sessionOrMatch="false" :creator="selectedItem.userName"/>
                    </v-tab-item>
                </v-tabs-items>

            </v-col>
        </v-row>
    </v-row>
</template>

<script>

    import {mapGetters} from "vuex";
    import MatchForm from '@/views/matches/matchesForm/matchForm.vue';
    import Chat from '../chat/chat.vue';

    export default {

        name: "matches",

        components: { MatchForm, Chat},

        data: () => ({

            tabs:0,

            matchArray : [],
            reasasibleMatchArray :[],
            matchChatData : [],
            selectedItem: {},

            showMoreButton: true,
            isRightPanelOpen: false,
            isFormReadOnly: true,
            isPageLoading: true,

            //filters
            search:"",
            searchPosition:"",
            searchOpponent:"",
            searchDate:"",

            currUserData:'',

            // Table headers
            headers: [
                { text: "ID", value: "id", align: "left", sortable: false, hidden: true, hide: false, hasAccess: ['Staff-Admin']},
                { text: "Date", value: "matchDate", align: "left", sortable: false, hidden: false, hide: false,},
                { text: "Opponent", value: "matchOpponent", align: "left", sortable: false, hidden: false, hide: false,},
                { text: "Performance", value: "matchPerformance", align: "left",sortable: false, hidden: false, hide: false,},
                { text: "Position", value: "matchPlayerPostition", align: "left",sortable: false, hidden: false, hide: false,},
                { text: "Fatigue",value: "matchFatigue", align: "left", sortable: false, hidden: false, hide: false,},
                { text: "Feeling",value: "matchFeeling", align: "left", sortable: false, hidden: false, hide: false,},
                { text: "", value: "actions", align: "left", sortable: false,hasAccess: ['Staff-Admin','Player-User','Coach-User'], hidden: false, hide: false,width: "100px",},
            ],
        }),

        computed: {
            ...mapGetters({
                GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
            }),

             /**
             * Computed Table Data
             *
             * Return a sorted array of JSON objects.
             *
             * @returns {Array} Array of processed JSON data
             */
            computedTableData() {
                const t = this
                let tableData = t.matchArray
                if(t.$vuetify.breakpoint.width <= 700 && t.search){
                    tableData = tableData.filter(data =>
                        data.matchFeeling.toUpperCase().match(t.search.toLocaleUpperCase())||
                        data.matchPlayerPostition.toUpperCase().match(t.search.toLocaleUpperCase())||
                        data.matchOpponent.toUpperCase().match(t.search.toLocaleUpperCase())||
                        data.matchPerformance.toString().match(t.search.toLocaleUpperCase())||
                        data.matchFatigue.toString().match(t.search.toLocaleUpperCase()))
                }

                if(t.searchPosition){
                    tableData = tableData.filter(data =>
                      data.matchPlayerPostition.toUpperCase().match(t.searchPosition.toLocaleUpperCase()))
                }
                if(t.searchOpponent){
                    tableData = tableData.filter(data =>
                      data.matchOpponent.toUpperCase().match(t.searchOpponent.toLocaleUpperCase()))
                }
                if(t.searchDate){
                    tableData = tableData.filter(data =>
                    // t.$moment(Number(data.sessionDate)).format('dddd Do MMMM \'YY').toUpperCase().match(t.searchDate.toUpperCase()))
                    this.$moment(data.matchDate, 'X').format('dddd Do MMMM \'YY').toUpperCase().match(t.searchDate.toUpperCase()))
                }
                // Sort alphabetically
                tableData = tableData.sort((a, b) => {
                    return a.matchDate > b.matchDate ? 1 : -1
                })
                return tableData
            },

            /**
             * Computed Headers
             *
             * Only display headers that are not set to hidden and the user has access to (via userLevel).
             *
             * @returns {[headers]} - filtered headers to render in the table
             */
            computedHeaders() {
                const t = this
                const headers = t.headers
                let filteredHeaders = []
                headers.forEach(header => {
                    // Only Admins can view the OPEN button
                    if (header.value === 'actions' && !header.hasAccess.includes(t.currUserData.userLevel)) {
                        return
                    }
                    // If the column is not hidden, show it
                    if (!header.hidden) {
                        filteredHeaders.push(header)
                    }
                })
                return filteredHeaders;
            },

            /**
             * This function detects if the matches of the user are more than 4 if yes then shows the load more button
             */
            computedLoadButton(){
                if(this.reasasibleMatchArray.length >4 && this.matchArray.length !=this.reasasibleMatchArray.length){
                    return true;
                }else{
                    return false;
                }
            },    

            /**
             * Computed Split Screen Columns
             *
             * Calculate the width of the left snd right panels, based on the currently selected functionality.
             * The left panel has the table, the right panel has the forms.
             *
             * @returns {{left: number, right: number}}
             */
            computedSplitScreenColumns() {
                const t = this
                let left = 12
                let right = 0
                if (t.$vuetify.breakpoint.width >= 1200) {
                    if (t.isRightPanelOpen ) {
                        left = 6;
                        right = 6;
                    }
                } else {
                    if (t.isRightPanelOpen ) {
                        left = 0;
                        right = 12;
                    }
                }
                return {left, right}
            },

             /**
             * Returns player positions in alphabetical order
             */
            sortPositions(){
                return this.playersPositions.sort()
            }
        },

        methods: {
            
            /**
             * Returns a smaller text for the match comment if the sting in more than 30 characters
             * @param comment The last comment from the chat
             * @return The last comment 
             */
            returnSubstring(comment){
                if(comment.length >30){
                    return comment.substring(0,30)+".."
                }else{
                    return comment
                }
            },

            /**
             * This method return the date in a nice format
             * @param data The date
             * @param shortLong If is going to be a long or short date
             * @return The date
             */
             dayTimeFormat(date, shortLong){
                let formatedDate ="";
                if( shortLong === "short"){
                    formatedDate = this.$moment(date, 'X').format('DD MMM YY');
                }else if(shortLong === 'long'){
                    formatedDate = this.$moment(date, 'X').format('dddd Do MMMM \'YY');
                }
               return formatedDate;
            },

            /**
             * This method return the date of the last message in a nice format
             * @return The date of the last message
             */
            dayTimeMinFormat(date){
                return this.$moment(date, 'X').format('DD MMM YY | HH:mm');
            },

            /**
             * This method detects if there are more mattches to be show if yes 
             * load those matches and display them
             */
            loadMore(){
                let remainMatches = this.matchArray.length - this.reasasibleMatchArray.length;
                if(remainMatches > 0 && remainMatches> 5){
                    for (var i=0; i<5; i++){
                        this.reasasibleMatchArray.push(this.matchArray[this.reasasibleMatchArray+i])
                    }
                }else if(remainMatches <= 0 ){
                    this.showMoreButton = false;
                }else{
                    this.reasasibleMatchArray = this.matchArray;
                    this.showMoreButton = false;
                }
            },

            /**
             * lIMITS the possible matches reviews available
             */
            limitSessionView(){
                if(this.matchArray.length > 5){
                    for (var i=0; i<5; i++){
                        this.reasasibleMatchArray.push(this.matchArray[i])
                    }
                }else{
                    this.reasasibleMatchArray = this.matchArray
                }
            },

            /**
             * Close Right Panel
             * When closing the form, reset it to ensure it opens as new.
             */
            closeRightPanel() {
                this.isFormReadOnly = true
                this.isRightPanelOpen = false
            },

            /**
             * Edit Form
             * Toggle the read only state of the form.
             */
            editForm() {
                this.isFormReadOnly = !this.isFormReadOnly;
            },

            /**
             * Emitted Close Right Panel
             * Take the emitted message and call to close the right panel.
             * @returns {Promise<void>}
             */

            async emittedCloseRightPanel() {
                this.readMatches();
                this.closeRightPanel();
                await this.readUserData();

            },

            /**
             * Emitted Delete From Dialog
             * Take the emitted message from the 'deleteIconDialog', and mark the selected document as deleted.
             * @returns {Promise<void>}
             */
            async emittedDeleteFromDialog() {
                await this.MIX_firestore_destroy(this.selectedItem.id, 'matchReviews');
                await this.MIX_firestore_update(this.GET_FIREBASE_userAuth.uid,"users", "matchesNo",  this.currUserData.matchesNo - 1)
                this.readMatches();
                this.closeRightPanel()
                this.MIX_alertBox({ color: "success", timeout: 5000, message: "Match Review deleted successfully", show: true })
            },

            /**
             * Open Right Panel
             * Open the CRUD form.
             * If the form is opened using the new button, it will be blank.
             * If the form is opened from the table, it will be assigned here and populated.
             * @param item JSON containing the selected data, if required
             */
            openRightPanel(item) {
                const t = this
                if (!item?.id) this.isFormReadOnly = false
                this.selectedItem = item
                this.matchChatData = item
                this.isRightPanelOpen = true
            },

            /**
             * Reads and sets User data
             */
            async readUserData(){
                let userDataResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, 'users');
                this.currUserData = userDataResult.data;
            },

            /**
             * Read the matchs that corrispond to the user
             */
            async readMatches(){
                this.matchArray = await this.MIX_firestore_readManyWhereCondition("matchReviews", "userId", "==", this.GET_FIREBASE_userAuth.uid);
                this.limitSessionView();
            }
        },

        async created(){
            this.readUserData();
            this.readMatches();
            this.isPageLoading=false;
        }

    }
</script>

<style scoped>
    .borderLeft {
        border-left: 4px solid #999999;
        min-height: calc(100vh - 128px);
    }
</style>
