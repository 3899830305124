<template>
	<div class="idpcontainer">

        <pageLoader :is-page-loading="isPageLoading"/>

		<div class="mt-4 mx-4"  >

			<div class="d-flex justify-space-between align-start">
				<div class="d-flex" >
					<app-btn 
						@click.native="updateShowPlayersFlag"
						class=" white--text"
						:class="$vuetify.breakpoint.width >= 600 && 'rowLabel'"
						color="grey lighten-1"
						height="54px"
						icon="icons8-go-back"
						/>
					
					<v-autocomplete v-if="idpExist"
						class="ml-4 mr-4 rounded-lg"
						chips
						flat
						filled
						multiple
						style="width:100%;"
						:items="sortOptions"
						label="Status"
						small-chips
						solo
						outlined
						hide-details="auto"
						v-model="performanceFilter"
						:return-object="true">
						<template v-slot:selection="data">
							<v-chip
								v-bind="data.attrs"
								:input-value="data.selected"
								close
								:color="data.item.value"
								@click="data.select"
								@click:close="remove(data.item)" >
								<span class="pr-2"> {{ data.item.text }}</span>
							</v-chip>
						</template>
					</v-autocomplete>

					<app-text class="ml-4" v-if="!idpExist" category="text-medium"> This player has not completed an IDP </app-text>
				</div>
				<!-- <v-spacer></v-spacer> -->
				<div  class="d-flex align-center">
					<div >
						<v-menu v-if="idpExist"  offset-y min-width="160px" origin="top right" right transition="scale-transition"  rounded-lg>

						<template v-slot:activator="{ on, attrs }">
							<v-icon class="" v-bind="attrs" color="grey" size="40" v-on="on">icons8-menu-vertical</v-icon>
						</template>

						<v-list> 
							<v-list-item @click="resetIdpDialog = true;">
								<app-icon icon="delete" color="secondary"  size="24"></app-icon>
								<app-text class="text-default ml-2">Delete this IDP</app-text>					
							</v-list-item>
						</v-list>

					</v-menu>
					</div>
					
				</div>
			</div>
		</div>
	
		<!--Categories-->
		<div v-if="$vuetify.breakpoint.width >= 600 && idpExist">
			<div class="ml-4"  v-for="(category, cIndex) in categoryArrays" :key="cIndex">
				<div  class="cardRow2" v-for="(subCategory,index)  in findSubCategories(category)" :key="index">

					<!--Labels-->
					<div class="rowLabel">
						<app-text  category="text-small" >{{category.replace("Skills", "")}}</app-text>
						<app-text category="text-medium-bold">{{subCategory}}</app-text>
					</div>

					<!--Skills-->
					<div v-for="(skill,index) in filterSkillsPerSubcategory(category,subCategory)" :key="index"> 
						<app-text v-if=" skill === 1 " class="ml-4 mt-8 grey--text" category="text-medium">Nothing To Show</app-text>
						<v-card v-else class="card white--text"
							flat
							:color="skill.status">
							<app-icon icon="vertical-menu" class="iconStyle" @click.native="openSkillDetails(skill ,category ,subCategory ); "/> 
							<app-text category="text-default">{{skill.name}}</app-text>
						</v-card>
					</div> 
				</div>
			</div>
		</div>
		
		<!--Category Tabs-->
		<div v-if="$vuetify.breakpoint.width < 600 && idpExist" class="mt-4">

			<!--Category Tabs-->
			<v-tabs v-model="tab" background-color="primary" show-arrows dark icon-and-text centered grow>
				<v-tabs-slider color="secondary"/>
				<v-tab v-for="category in categoryArrays" :key="category" @change="panel=null">{{category.replace("Skills", "")}}</v-tab>
			</v-tabs>

			<!--Subcategory Headers-->
			<v-tabs-items v-model="tab" >
				<v-tab-item v-for="category in categoryArrays" :key="category" class="appBackground">
				
					<div class="pa-4" v-for="(subCategory,index)  in findSubCategories(category)" :key="index">

						<!--Subcategory-->
						<app-text class="mt-4" category="text-medium-bold">{{subCategory}}</app-text>

						<!--Cards-->
						<div class="cardRow3 mt-4"  v-for="(skill,index) in filterSkillsPerSubcategory(category,subCategory)" :key="index">
							<app-text v-if=" skill === 1 " class="ml-4 grey--text" category="text-medium">Nothing To Show</app-text>
							<v-card v-else class=" card pa-4 white--text"
								flat
								:color="skill.status">
								<app-icon icon="vertical-menu" class="iconStyle" @click.native="openSkillDetails(skill ,category ,subCategory ); "/> 
								<app-text category="text-default" class="white--text">{{skill.name}}</app-text>
							</v-card>
						</div> 
					</div>

				</v-tab-item>
			</v-tabs-items>
				
		</div>

		<!--Dialog For Skills Iformation-->
        <v-dialog style="z-index: 3000 !important;" v-model="dialogFlag"  max-width="624">
            <v-card class="white">
				<pageLoader :is-page-loading="isPageLoading"/>

				<!--title-->
				<v-toolbar class="pl-2" color="secondary"> 
					<app-text category="text-medium">{{dialogSkill.name}}  </app-text>
					<v-spacer/>
	                <close-icon @click.native="dialogFlag = false" /> 
				</v-toolbar>

				<!--Content-->
				<div class="pa-4">
					<app-text category="text-default-bold">Change status level to...?</app-text> 

					<!--Change Status select-->
					<div class="d-flex justify-space-between ">
						<app-input input-type="select"
							@input=" statusChange =true"
							:append-icon="'icons8-search'"
							class="mt-2"
							:items="sortOptions"
							:return-object="true"
							style="width:100%"
							v-model="changeableStatus"/>
					</div>

					<!--Chart-->
					<div v-if="checkChartData()" class="mt-8">
						<app-text category="text-default-bold">This graph needs two or more points to work</app-text> 
					</div>
					<div class="mt-8" id="chart">
						<apexchart ref="skillsChart" type="radar" height="480px" :options="chartOptions" :series="series"></apexchart>
					</div>

					<!--View Related Sessions Button-->
					<app-btn
						block
						color="primary"
						:label="$vuetify.breakpoint.width > 600 ? `VIEW ${dialogSkill.name} SESSIONS` : 'VIEW RELATED SESSIONS'"
						@click.native="returnFilter(dialogSkill.name)"/> 

				</div>	
            </v-card>
        </v-dialog>
		
		<!--Dialog For Skill Status change-->
        <v-dialog v-model="statusChange" max-width="512">
            <v-card class="white">

				<!--Title-->
               <v-toolbar class="pl-2" color="secondary"> 
					<app-text category="text-medium">Status Change</app-text>
					<v-spacer/>
	                <close-icon @click.native="statusChange = false" /> 
				</v-toolbar>       
                
				<!--Content-->
				<div class="pa-4">

					<!--Warning-->
                    <app-text v-if="dialogSkill.status!=undefined && changeableStatus.text!=undefined " category="text-default" >
						You are about to change <b>{{dialogSkill.name}}</b> status
						 to <b>{{changeableStatus.text}}</b>.
                    </app-text>
                    <app-text category="text-default-bold" class="mt-4">Are you sure you want to do this?</app-text>
					

					 <div class="d-flex justify-space-between mt-4">
							<app-btn @click.native="statusChange = false;" color="grey" icon="icons8-cancel"  label="No"/>                
							<app-btn @click.native="updateSkill(dialogSkill); updateRadarChart(dialogSkill.category, dialogSkill.subCategory);"  icon="icons8-checkmark-yes" color="success" label="Yes"/>
					</div>
				</div>
            </v-card>
        </v-dialog>

		<!--Dialog For Reseting The Idp-->
        <v-dialog v-model="resetIdpDialog" max-width="512">
            <v-card class="white">

				<!--Title-->
               <v-toolbar class="pl-2" color="secondary"> 
					<app-text category="text-medium">Reset Idp</app-text>
					<v-spacer/>
	                <close-icon @click.native="resetIdpDialog = false" /> 
				</v-toolbar>       
                
				<!--Content-->
				<div class="pa-4">

					<!--Warning-->
                    <app-text category="text-default" >
						You are about reset the IDP for  <b>{{usersData.userName}}</b>.
                    </app-text>
                    <app-text category="text-default-bold" class="mt-4">Are you sure you want to do this?</app-text>
					
					<div class="d-flex justify-space-between mt-4">
						<app-btn @click.native="resetIdpDialog = false;" color="grey" icon="icons8-cancel"  label="No"/>                
						<app-btn @click.native="resetIdp()" icon="icons8-checkmark-yes" color="success" label="Yes"/>
					</div>
				</div>
            </v-card>
        </v-dialog>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";

	export default {

		name: "PlayerIdp",
		props : ['usersData', 'skills'],
		data: () => ({

			isPageLoading: true,
			statusChange: false,
			idpExist:true,
			resetIdpDialog: false,

			performanceFilter:"",

			tab:null,
			optionsArray: [{text:"Strength", value:"strength", sort:'3'},
							{text:"Concern", value:"concern", sort:'0'},
							{text:"Work On", value:"workon", sort:'1'},
							{text:"Leader", value:"leader", sort:'4'}],
							
			idpSelection: [],// slills array FROM iDP COLLECTION
			categoryArrays:[],  // sorted category of skills
			changeableStatus: {},
			skillsArray: [],

			dialogSkill : {},
			dialogFlag :false,

			//Chat Information
			series: [{
				data: [1,2,3,4],
			}],
			chartOptions: {
				chart: {
					type:"radar",
					toolbar: {
						show: false
					},
				},
				xaxis: {
					categories: ['a', 'b', 'c', 'd'],
					type: 'category',
					labels: {
						style: {
							fontSize: '14px',
							fontWeight: 700,
						},
   					}
				},
				yaxis: {
      				show: false,
				},
				markers: {
					size: 0
				},     
				fill: {
					opacity: 0.5,
					colors: ["#d32f2f"],
				},
				stroke: {
					show: false,
				}
			},
		}),

		computed:{
			...mapGetters({
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
			}),

			/**
			 * Sort status options in performance order
			 */
			sortOptions(){
				return this.$sortBy(this.optionsArray, 'sort')
			},
			
		},
	
		methods: {
			/**
			 * This methods will reset the Idp of the user
			 * Step one remove user idp from idp collection
			 * Step two - reset idpCompleted in 
			 */
			resetIdp(){
				
				this.MIX_firestore_destroy( this.idpSelection.id , 'idp');

				// async MIX_firestore_update(documentId, collectionName, fieldToUpdate, value) {
				this.MIX_firestore_update ( this.idpSelection.userId, 'users', 'idpCompleted', false)

				this.idpExist = false;
				this.resetIdpDialog = false;
			},

			checkChartData(){
				let count= 0;
				for (var i=0; i<this.series[0].data.length; i++ ){
					if(this.series[0].data[i] != 0){
						count++;
					}
				}
				if( count >= 2){
					return false
				}else{
					return true
				}
			},
			/**
			 * Remove an argument from the performance filter
			 */
			remove(item) {
				const index = this.performanceFilter.indexOf(item)
				if (index >= 0){
					this.performanceFilter.splice(index, 1)
				}
			},
			/**
			 * Emits the updateShowPlayersFlag which indicates that a specific user has been selected
			 * and ensures that we show his pic, name and position on the left top corner
			 */
			updateShowPlayersFlag(){
				this.$emit('updateShowPlayersFlag',false);
			},
			/**
			 * Closes the dialog and emits filterSessions,
			 * change to the individual player session with a predifined filter
			 */
			returnFilter(filter){
				this.dialogFlag = false
				this.$emit('filterSessions',filter);
			},

			/**
			 * Update the status of a skill
			 */
			async updateSkill(skill){
				this.dialogSkill.status = this.changeableStatus.value;

				var index = this.idpSelection.usersIdp.findIndex(x=>x.skillId === skill.skillId)
				if(index !== -1){
					// Index is containted in the array
					this.idpSelection.usersIdp[index].skillId = skill.skillId
					this.idpSelection.usersIdp[index].status = skill.status
				}else{
					// Index is not containted in the array
					// Create a new obj
					var idpObj = new Object();
					idpObj.skillId = skill.skillId
					idpObj.status = skill.status
					this.idpSelection.usersIdp.push(idpObj);	
				}		
		
				await this.MIX_firestore_update(this.idpSelection.id, "idp", "usersIdp",this.idpSelection.usersIdp);
				this.statusChange = false;
                this.MIX_alertBox({ color: 'success', timeout: 3000, message: this.dialogSkill.name + ' change to ' + this.changeableStatus.text, show: true })
			},

			/**
			 * This methods is reposnponsible for rendering the radar chat everytime a skill status change
			 */
			updateRadarChart(category, subCategory){
				let array = this.filterSkillsPerSubcategory(category,subCategory);
				let chartCategories=[]
				let chartSeries = [];
				for (var i =0; i<array.length; i++){
					chartCategories.push(array[i].name)
					switch(array[i].status) {
						case 'strength':
							chartSeries.push(3);
							break;
						case "concern":
							chartSeries.push(1);
							break;
						case "workon":
						chartSeries.push(2);
						break;
						case "leader":
						chartSeries.push(4);
						break;
						case "blank":
						chartSeries.push(0);
						break;
					default:
						chartSeries.push(0);
					}
				}
				this.chartOptions.xaxis.categories = chartCategories;
				this.series[0].data = chartSeries
				if(this.$refs.skillsChart !==undefined ){
					this.$refs.skillsChart.updateOptions({
						xaxis: {
							categories: this.chartOptions.xaxis.categories //ie ["a","b","c","d"]
						}
					})
				}
			},

			/**
			 * This methods finds out the skill that corresponds to the selected
			 * category and subcategory and open the dialog with the radar chart
			 * @param skill The skill object
			 * @param category
			 * @param subCategory
			 */
			openSkillDetails(skill, category, subCategory){
				this.updateRadarChart(category, subCategory)
				this.dialogSkill = skill;
				this.changeableStatus =skill.status
				this.dialogFlag = true;
			},
		
			/**
			 * This method returns the skills for each subcategory
			 * @param {*} tabCategory The select tab category
			 * @param {*} headerSubcategory the heafer of the selection of the skill as subcategory
			 * @return The cross-reference of the skills for the selected category and the respected subcategory
			 */
			filterSkillsPerSubcategory(tabCategory, headerSubcategory){
				let newArray = [];// initialize array
				for (var i=0; i< this.skillsArray.length; i++){
					// Check is the skills has the respected category and subcategory
					if(this.skillsArray[i].category === tabCategory && this.skillsArray[i].subCategory ===headerSubcategory){
						if(this.performanceFilter != '' && this.performanceFilter !=null){
							if(this.performanceFilter.some(e=> e.value === this.skillsArray[i].status)){
								newArray.push(this.skillsArray[i])
							}
						}else{
							newArray.push(this.skillsArray[i])
						}
					}
				}
				if( newArray.length !=0){
					return newArray
				}else{
					return newArray.push( {})
				}
			},

			/**
			 * This method finds out the number and the respected name for each subcategory for the selected tab
			 * @param {*} tabCategory The name of the selected tab
			 * @return The selection(array) of the the subcategories
			 */
			findSubCategories(tabCategory){
				const subCategories = [];
				this.skillsArray.filter(element => {
					//Check for duplicate values
					const isDuplicate = subCategories.includes(element.subCategory);
					// If element.subCategory (Name) does not exist add it
					if (!isDuplicate && element.category === tabCategory) {
						subCategories.push(element.subCategory);
						return true;
					}
					// if it is duplicate end complexity and move to the next element
					return false;
				});
				return subCategories;
			},

			/**
			 * This method finds out the number and the respected name for each known category 
			 * @return The selection(array) of the categories
			 */
			findCategories(){
				const categoryArrays = [];
				if(this.idpSelection === undefined){
					this.idpExist = false;
				}else {
					this.skillsArray.filter(element => {
					//Check for duplicate values
					const isDuplicate = categoryArrays.includes(element.category);
					if (!isDuplicate) {
						var removedText = element.category
						categoryArrays.push(removedText);
						return true;
					}
					// if it is duplicate end complexity and move to the next element
					return false;
				});
				this.categoryArrays= categoryArrays;
				}
	            this.isPageLoading = false
			},
			/**
			 * This method filter the skills according to the current(Opened) player position
			 */
			async filterSkills(){

				/**Filter skills according to user position */
				for (var i=0; i<this.skills.length; i++){
					if ( this.skills[i].fieldPosition.includes(this.usersData.userForwardBack)){
						this.skillsArray.push(this.skills[i])
					}	
				}
				/** If the player has created/completed an idp populate the data according to his selection */
				if(this.idpSelection != undefined){
					this.skillsArray.forEach( element => {
						var index = this.idpSelection.usersIdp.findIndex(x=>x.skillId === element.skillId)
						if(index !== -1){
							element.status = this.idpSelection.usersIdp[index].status
						}
					})
				}
			}
		},

		async created() {
			//Get user IDP from DB
			let idpSelectionArray = await this.MIX_firestore_readManyWhereCondition("idp", "userId", "==", this.usersData.id);
            this.idpSelection = idpSelectionArray[0];
			await this.filterSkills()
			this.findCategories();
		},
	};
</script>

<style scoped>
	.iconStyle{
		position: absolute;
		top: 8px;
		right: 8px;
	}
	.idpcontainer{
		height: 100%;
		width: 100%;
	}
	.card{
		padding: 8px 16px;
		display: flex;
		align-items: center;
		height: 100%;
		border-radius: 8px 16px 8px 16px;
	}	
	.rowLabel{
		width: 128px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.cardRow2{
		display:grid;
		grid-template-columns: 128px repeat(7, 1fr);
		width:100%;
		grid-gap:16px;
		margin: 8px 0;
		height: calc((100vh - 282px) / 7 );
	}
	.cardRow3 {
		display:grid;
		grid-gap:16px;
	}
	.concern {
		background-color: #C62828 !important;
	}
	.workon {
		background-color: #EF6C00 !important;
	}
	.strength {
		background-color: #2E7D32 !important;
	}
	.leader {
		background-color: #0277BD !important;
	}
	.blank {
		background-color: #999999 !important;
	}
</style>
