<template>
	<div style="width: 100%">

		<!--Page Title-->
        <page-title class="pa-4 " icon="icons8-grid-2" pageTitle="IDP" description="Complete and view your Individual Development Passport"/>
		<!-- TABS -->
		<v-tabs v-model="tab" background-color="primary" class="mt-n4" show-arrows dark icon-and-text centered grow>
			<v-tabs-slider color="secondary"/>
			<v-tab v-for="category in categoryArrays" :key="category" @change="panel=null">{{category.replace("Skills", "")}}</v-tab>
		</v-tabs>

		<!-- Tab Items-->
		<v-tabs-items v-model="tab" >
			<v-tab-item v-for="category in categoryArrays" :key="category" class="appBackground pa-4">
				<v-row v-for="(subCategory,index)  in findSubCategories(category)" :key="index" no-gutter class="pa-4">
					
					<!--SubCategory-->
					<app-text category="text-medium-bold" >{{subCategory}}</app-text>

					<!-- Skill Expansion Panel-->
					<v-expansion-panels v-for="(skill,i) in filterSkillsPerSubcategory(category,subCategory)" 
						flat
						:key="i" 
						v-model="activePanels.panel[index][i]">

						<v-expansion-panel class="rounded-lg mt-4">
							<!-- Skill Name and Expansion commands-->
							<v-expansion-panel-header 
								:class="skill.status" 
								class="white--text text-left" 
								:disable-icon-rotate="skill.status !== 'blank'" 
								disable>
								
								<span class="font-weight-bold">{{ skill.name }}</span>
								<template v-slot:actions>
									<v-icon color="white" v-if="skill.status === 'blank'"> mdi-menu-down </v-icon>
									<v-icon color="white" v-else> mdi-check-circle-outline </v-icon>
								</template>
							</v-expansion-panel-header>

							<!-- Description and buttons-->
							<v-expansion-panel-content class="rounded-b-lg">

								<app-text class="mt-4" >{{ skill.description }} </app-text>

								<app-text class="mt-4" category="text-default-bold" >How do you feel you perform in this area?</app-text>
								<!--Diplsay Buttons only if the idp saved as draft-->
								<v-row v-if="isIdpCompleted" no-gutters class="mt-4"> 
									<v-col :cols="$vuetify.breakpoint.width < 680 ? '6': '3' "
										:class="$vuetify.breakpoint.width < 680 ? 'pr-2': 'pr-2' ">
										<app-btn label="Concern" :block="true" color="concern white--text"
										@click.native="skill.status = 'concern'; activePanels.panel[index][i]++; updateIdp(skill);"/> 
									</v-col>
									<v-col :cols="$vuetify.breakpoint.width < 680 ? '6': '3' " 
										:class="$vuetify.breakpoint.width < 680 ? 'pl-2': 'px-2' ">
										<app-btn label="Work On" :block="true"   color="workon white--text"
										@click.native="skill.status = 'workon'; activePanels.panel[index][i]++; updateIdp(skill)"/>
									</v-col>
									<v-col :cols="$vuetify.breakpoint.width < 680 ? '6': '3'  " 
										:class="$vuetify.breakpoint.width < 680 ? 'mt-4 pr-2': 'px-2' ">
										<app-btn label="Strength" :block="true"  color="strength white--text"
										@click.native="skill.status = 'strength'; activePanels.panel[index][i]++; updateIdp(skill)"/>
									</v-col>
									<v-col  :cols="$vuetify.breakpoint.width < 680 ? '6': '3'  " 
									:class="$vuetify.breakpoint.width < 680 ? 'mt-4 pl-2': 'pl-2' ">
										<app-btn label="Leader" :block="true"  color="leader white--text"
										@click.native="skill.status = 'leader'; activePanels.panel[index][i]++; updateIdp(skill)"/>
									</v-col>
								</v-row>

							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
					<!-- End of Skills expnasion panels-->
				</v-row>

				<v-divider class="mt-4 px-4 " ></v-divider>

				<!--Focus-->
				<div class="mt-4">
					<v-textarea  
						class="rounded-lg"
						flat
						filled
						:disabled="!isIdpCompleted"
						v-model="form.focus[tab]" 
						outlined label="How do you aim to improve on your skills" 
						rows="1" hide-details="auto" />
				</div>
			</v-tab-item>

			<!-- Save as Draft and save buttons-->
			<div v-if="isIdpCompleted" class="pa-1 flex-grow-1 appBackground">
				<app-btn v-if="form.usersIdp.length != skillsArray.length" @click.native="saveIdp()" block depressed large color="update" icon="icons8-save" label="SAVE AS DRAFT"/>
				<app-btn v-else @click.native="saveIdp()" block depressed large color="update" icon="icons8-save" label="SAVE"/>
			</div>
		</v-tabs-items>
	</div>
</template>

<script>
	import {mapGetters} from "vuex";

	export default {

    	name: "IDP",

		data: () => ({
			idpExist: false,//flag used to determine if user has incompleted or previous draft of idp
			skillsArray: [], // slills array
			categoryArrays:[], // sorted category of skills
			tab: null,
			isIdpCompleted:true,

			// User IDP form
			form:{
				userId:"",
				usersIdp: [],
				focus:[],
				completed:false // determines if the idp is completed
			},

			//Expansion Panel matrix
			activePanels: {
				panel: [{},{},{},{},{},{},{},
						{},{},{},{},{},{},{},
						{},{},{},{},{},{},{}]
			},
		}),

		computed:{
			...mapGetters({
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
			}),
		},

		methods: {
			
			/**
			 * This method returns the skills for each subcategory
			 * @param {*} tabCategory The select tab category
			 * @param {*} headerSubcategory the heafer of the selection of the skill as subcategory
			 * @return The cross-reference of the skills for the selected category and the respected subcategory
			 */
			filterSkillsPerSubcategory(tabCategory, headerSubcategory){
				let skillsPerSubcategory = []; // initialize array
				for (var i=0; i< this.skillsArray.length; i++){
					// Check is the skills has the respected category and subcategory
					if(this.skillsArray[i].category === tabCategory && this.skillsArray[i].subCategory ===headerSubcategory){
						skillsPerSubcategory.push(this.skillsArray[i])
					}
				}
				return skillsPerSubcategory;
			},

			/**
			 * This method finds out the number and the respected name for each subcategory for the selected tab
			 * @param {*} tabCategory The name of the selected tab
			 * @return The selection(array) of the the subcategories
			 */
			findSubCategories(tabCategory){
				const subCategories = [];
				this.skillsArray.filter(element => {
					//Check for duplicate values
					const isDuplicate = subCategories.includes(element.subCategory);
					// If element.subCategory (Name) does not exist add it
					if (!isDuplicate && element.category === tabCategory) {
						subCategories.push(element.subCategory);
						return true;
					}
					// if it is duplicate end complexity and move to the next element
					return false;
				});
				return subCategories;
			},

			/**
			 * This method finds out the number and the respected name for each known category 
			 * @return The selection(array) of the categories
			 */
			findCategories(){
				const categoryArrays = [];
				this.skillsArray.filter(element => {
					//Check for duplicate values
					const isDuplicate = categoryArrays.includes(element.category);
					// If element.subCategory (Name) does not exist add it
					if (!isDuplicate) {
						categoryArrays.push(element.category);
						return true;
					}
					// if it is duplicate end complexity and move to the next element
					return false;
				});
				this.categoryArrays= categoryArrays;
			},

			/**
			 * This method updates/added the new/previous skill of the IDP
			 * @param {*} skill The skills the user had open in the expnasion panel
			 */
			updateIdp(skill){
				//check for the existance of the parameter
				
				var index = this.form.usersIdp.findIndex(x=>x.skillId === skill.skillId)
				if(index !== -1){
					// Index is containted in the array
					this.form.usersIdp[index].skillId = skill.skillId
					this.form.usersIdp[index].status = skill.status
				}else{
					// Index is not containted in the array
					// Create a new obj
					var idpObj = new Object();
					idpObj.skillId = skill.skillId
					idpObj.status = skill.status
					this.form.usersIdp.push(idpObj);	
				}		
			},

			/**
			 * This method determines if the user has completed the IDP or save it as draft
			 * if it is then update the new selections status of the skills if not create
			 * a document in the database
			 */
			async saveIdp(){
				//Check if any of the skills have not been completed
				const isBlankStatus = this.form.usersIdp.length != this.skillsArray.length

				if(!isBlankStatus){
					// IF all the skills have been completed
					this.form.completed = true;
					await this.MIX_firestore_update(this.GET_FIREBASE_userAuth.uid,"users", "idpCompleted", true)
				}

				//IDP exist is a flag used to determine if user has incompleted or previous draft of idp
				if(this.idpExist){
					//If IDP for the player exist in the firebase previously saved as draft
					await this.MIX_firestore_updateMultipleFields(this.form.id,"idp", this.form);
					this.MIX_alertBox({ color: "success", timeout: 3000, message: "Your IDP selections have been submitted !", show: true });
					this.MIX_go('/');
				}else{
					//If IDP do not exist in the firebase
					this.form.userId =this.GET_FIREBASE_userAuth.uid
					await this.MIX_firestore_create_autoId( "idp", this.form);			
					this.MIX_alertBox({ color: "success", timeout: 3000, message: "Your IDP selections have been submitted !", show: true });
					this.MIX_go('/');
				}
			},

			/**
			 * Check if the idp exist
			 */
			async checkIdp(){

				// The idp in the database if it exist
				let idpInDB = await this.MIX_firestore_readManyWhereCondition("idp", "userId", "==", this.GET_FIREBASE_userAuth.uid);

				// If exist sets up  the form and the skills with the users previous selection
				// * - TO BE REMOVED
				if(idpInDB.length !=[]){
					this.idpExist = true;
					this.form = idpInDB[0]
					// this.skillsArray = this.form.usersIdp;

					this.skillsArray.forEach( element => {

						var index = this.form.usersIdp.findIndex(x=>x.skillId === element.skillId)
						if(index !== -1){
							element.status = this.form.usersIdp[index].status
						}

					})

					if(idpInDB[0].completed){
						this.isIdpCompleted = false;
					}else{
						this.isIdpCompleted = true;
					}

				}else{
					// If the IDP does not exist set up skills and idp as the fundumentals
					this.idpExist = false;
					// this.form.usersIdp = this.skillsArray
				}
			},
		},

		async created() {
			let userDataResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, 'users');
			// Find slills in respect with users position
			this.skillsArray = await this.MIX_firestore_readManyWhereCondition("skills", "fieldPosition", "array-contains", userDataResult.data.userForwardBack);
			this.findCategories();
			this.checkIdp();
		},
	}
</script>

<style scoped>
	.concern {
		background-color: #C62828 !important;
	}
	.workon {
		background-color: #EF6C00 !important;
	}
	.strength {
		background-color: #2E7D32 !important;
	}
	.leader {
		background-color: #0277BD !important;
	}
	.blank {
		background-color: #757575 !important;
	}
</style>
