<!--
Page Title

Renders a full-width page title with an (optional) icon and (optional) description.
-->
<template>
    <div class="pt-container">

        <div class="d-flex">

            <v-icon v-if="icon" :class="icon" class="mr-4" color="secondary" size="48"/>

            <app-text category="text-xlarge" class="primary--text">{{ pageTitle }}</app-text>

        </div>

        <app-text v-if="description" class="mt-4 grey--text" category="text-small" >{{ description }}</app-text>

        <v-divider v-if="divider" class="mt-2 mb-1"/>

    </div>
</template>

<script>
export default {
    props: ['description', 'divider', 'icon', 'pageTitle'],
}
</script>

<style scoped >
    .pt-container {
        display: flex;
        flex-direction: column;

        margin-bottom: 16px;
        width: 100%;
    }
</style>
