<template>
	<div app sticky class="secondary  align-end" fluid style="padding: 0px !important;  z-index: 1000 !important">

		<!-- User Profile-->
		<v-list>
			<v-list-item class="px-2" link @click="MIX_go('/userProfile')">
				<v-list-item-avatar>
					<v-img v-if="userData.userProfile !=''" :src="userData.userProfile"></v-img> 
					<app-icon v-else icon="users"  size="32"></app-icon>
				</v-list-item-avatar>
			</v-list-item>
			<v-list-item link @click="MIX_go('/userProfile')">
				<v-list-item-content >
					<v-list-item-title class="text-h6"> {{userData.userName}} </v-list-item-title>
					<v-list-item-subtitle>{{userData.userEmail}}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<v-divider></v-divider>

		<!--users-->
		<v-list v-if="['Staff-Admin', 'Coach-User'].includes(userData.userLevel)" nav dense class="">
			<v-list-item link @click="MIX_go('/user-directory')">
				<v-list-item-icon>
					<v-icon>icons8-user-2</v-icon>
				</v-list-item-icon>
				<v-list-item-title>Users</v-list-item-title>
			</v-list-item>
		</v-list>

		<!--Matches-->
		<v-list v-if="['Player-User'].includes(userData.userLevel)" nav dense class="">
			<v-list-item link @click="MIX_go('/matches')">
				<v-list-item-icon>
					<v-icon>icons8-rugby-3</v-icon>
				</v-list-item-icon>
				<v-list-item-title>Matches</v-list-item-title>
			</v-list-item>
		</v-list>

		<!--Sessions-->
		<v-list v-if="['Player-User'].includes(userData.userLevel)" nav dense class="">
			<v-list-item link @click="MIX_go('/sessions')">
				<v-list-item-icon>
					<v-icon>icons8-stopwatch</v-icon>
				</v-list-item-icon>
				<v-list-item-title>Sessions</v-list-item-title>
			</v-list-item>
		</v-list>

		<!--Idp-->
		<v-list v-if="['Player-User'].includes(userData.userLevel)" nav dense class="">
			<v-list-item link @click="MIX_go('/idp')">
				<v-list-item-icon>
					<v-icon>icons8-grid-2</v-icon>
				</v-list-item-icon>
				<v-list-item-title>IDP</v-list-item-title>
			</v-list-item>
		</v-list>
		
		<!--Coach-->
		<v-list v-if="['Staff-Admin', 'Coach-User'].includes(userData.userLevel)" nav dense class="">
			<v-list-item link @click="MIX_go('/squad')">
				<v-list-item-icon>
					<v-icon>icons8-team</v-icon>
				</v-list-item-icon>
				<v-list-item-title>Squad</v-list-item-title>
			</v-list-item>
		</v-list>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import { doc, onSnapshot } from "firebase/firestore";

	export default {
		
		data: () => ({
			userData:'', 
		}),

		computed:{
			...mapGetters({
				GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
			})
		},
			
		mounted(){
			let userData = "";
			onSnapshot(doc(this.$firebase.db, 'users', this.GET_FIREBASE_userAuth.uid), (doc) => {
				userData = doc.data();
				this.userData= userData
			});
		},

		async created() {
			let userData = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, 'users');
			this.userData = userData.data;
		}
	};

</script>
