<template>
    <v-row class="ma-0 pa-0" no-gutters>

		<!--Add Photo Profile Layout-->
		<div v-if="chooseLayout">

			<!-- File input -->
			<input type="file" ref="fileinput" style="display: none" @change="previewFile" :accept="GET_photoUpload.allowedTypes"/>

			<!--Add photo button | Progress bar-->
			<v-col class="d-flex align-center">

				<!--Add button-->
				<v-btn v-if="!isUploading" @click.native="fileClick" class="white--text mr-4" color="primary">
					<v-icon class="icons8-camera mr-2" color="white"/>
					Add Photo
				</v-btn>

				<!--Progress bar-->
				<v-progress-linear v-if="isUploading" v-model="uploadValue" height="36">
					<app-text category="text-default">{{ Math.ceil(uploadValue) }}%</app-text>
				</v-progress-linear>
			</v-col>
		</div>

		<!-- V-card home page uplaod-->
		<v-card v-if="!chooseLayout" class="pa-5 mt-3 d-flex" elevation="0" style="width: 100%" @click="fileClick">
			<input type="file" ref="fileinput" style="display: none" @change="previewFile" :accept="GET_photoUpload.allowedTypes"/>					
			<div><v-icon x-large class="primary--text">icons8-user-2</v-icon></div>
			<div class="ml-5">
				<span class="primary-text text-h7 font-weight-bold">Upload your Photo</span><br />
				<span class="text--secondary">Click here to upload.</span>
			</div>
		</v-card>

    </v-row>
</template>

<script>
import {mapGetters} from 'vuex';

export default {

	props: {
		chooseLayout: {
			type: Boolean,
			default: false
		},
	},
	
	data: () => ({
		fileData: null,		
	}),

	computed: {
		...mapGetters({
			GET_photoUpload: 'photoUpload_store/GET_photoUpload',
			GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
		})
	},

	methods: {
		fileClick() {
			this.$refs.fileinput.click();
		},
		previewFile: async function (event) {
			this.uploadValue = 0;
			this.fileURL = null;
			this.fileData = event.target.files[0]; // assign fileData for preview
		},
	},
	
	watch: {
		fileData: async function () {
			const t = this;
			const docLink = t.$attrs.docLink.documentId;
			// Compress the file
            const compressedFile = await t.MIX_compressImage(t.fileData)
			const uploadFileResult = await t.MIX_uploadFile(docLink, t.GET_photoUpload.folder, compressedFile, docLink, t.GET_photoUpload.type, t.GET_photoUpload.typeId);
			t.MIX_photoUploadResult(uploadFileResult.data);
			t.userDataResult = await this.MIX_firestore_read(docLink, 'users');
		},
	}
}
</script>
