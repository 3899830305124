<template>
    <v-form class="">

        {{ computedFormData }}

        <!--Form-->
        <div class="" no-gutters>

            <!--Profile Picture | User Details-->
            <v-row no-gutters style="width: 100%">

                <!--Profile picture-->
                <v-col
                    class="d-flex justify-center align-center pa-2 rounded-lg white profileImage-container"
                    :class="$vuetify.breakpoint.width >= 600 ? 'flex-grow-0 mr-4' : ''"
                    :cols="$vuetify.breakpoint.width < 600 && 12"
                   >

                    <!--If an image is present, render it-->
                    <div v-if="form.userProfile && !tempImage"  
                         class="d-flex flex-column align-center">

                        <!--Image-->
                        <v-img :src="form.userProfile"
                               class="rounded-lg" cover height="200" width="200" />

                        <!--Upload button-->
                        <div
                             style="position: absolute; z-index: 9; margin-top: 150px">

                            <photoupload class="mr-n4" v-if="!computedIsFormReadOnly"
                            :chooseLayout="true"
                                         allowedtypes="image/*"
                                         :docLink="{collection: 'users', documentId: form.id}"
                                         folder="users-profile-pictures"/>
                        </div>

                    </div>

                    <!--If a tempImage (upload preview) is present, render it-->
                    <div v-else-if="tempImage"
                         class="d-flex flex-column align-center">

                        <!--Image-->
                        <v-img :src="tempImage"
                               class="rounded" cover height="216" width="216"/>

                        <!--Upload button-->
                        <div style="position: absolute; z-index: 9; margin-top: 200px">

                            <photoupload 
                                         :chooseLayout="true"
                                        class="mr-n4"
                                         allowedtypes="image/*"
                                         :docLink="{collection: 'users', documentId: form.id}"
                                         folder="users-profile-pictures"/>

                        </div>

                    </div>

                    <!--If neither an image or tempImage is present, render an icon-->
                    <div v-else class="d-flex flex-column align-center" >

                        <!--Image-->
             			<app-icon icon="users" color="secondary" size="200"></app-icon>


                        <!--Upload button-->
                        <div v-if="!computedIsFormReadOnly"
                             style="position: absolute; z-index: 9; margin-top: 150px">

                            <photoupload
                                         :chooseLayout="true"
                                        class="mr-n4"
                                         style="width: 100%"
                                         allowedtypes="image/*"
                                         :docLink="{collection: 'users', documentId: form.id}"
                                         folder="users-profile-pictures"/>

                        </div>

                    </div>

                </v-col>

                <!-- User details -->
                <v-col :class="$vuetify.breakpoint.width >= 600 && 'flex-grow-1'"
                       :cols="$vuetify.breakpoint.width < 600 && 12">

                    <!--Name-->
                    <app-input input-type="textInput"
                               :class="$vuetify.breakpoint.width < 600 && 'mt-4'"
                               :error="errors.userName"
                               :error-messages="errors.userNameErrorMessage"
                               :is-form-read-only="computedIsFormReadOnly"
                               label="Full Name"
                               v-model.trim="form.userName"/>

                    <!--Email-->
                    <app-input input-type="textInput"
                               class="mt-4"
                               :error="errors.userEmail"
                               :error-messages="errors.userEmailErrorMessage"
                               :is-form-read-only="computedIsFormReadOnly"
                               label="Email"
                               v-model.trim="form.userEmail"/>

                    <!--Telephone-->
                    <app-input input-type="textInput"
                               class="mt-4"
                               :error="errors.userTelephone"
                               :error-messages="errors.userTelephoneErrorMessage"
                               :is-form-read-only="computedIsFormReadOnly"
                               label="Telephone"
                               type="tel"
                               v-model="form.userTelephone"/>

                   
                </v-col>
                

            </v-row>

            <div class="my-4" >
                <app-text category="text-medium" class="darkgrey--text">Sport Details</app-text>
                <v-divider class="mt-2"/>
            </div>

            <div :class="$vuetify.breakpoint.width > 600 ? 'd-flex mt-4': 'mt-4 '">
                <!--Sport Type-->
                <app-input input-type="select"
                    style="width:100%;"
                    :class="{'': $vuetify.breakpoint.smAndUp, 'mt-4': $vuetify.breakpoint.xsOnly}"
                    :error="errors.userSportType"
                    :error-messages="errors.userSportTypeErrorMessage"
                    :is-form-read-only="computedIsFormReadOnly"
                    :items="sportTypeArray"
                    label="Sport Type"
                    v-model.trim="form.userSportType"/>
            <!--positions-->
                <app-input input-type="select"
                    style="width:100%;"
                    :class="{'ml-4 ': $vuetify.breakpoint.smAndUp, 'mt-4': $vuetify.breakpoint.xsOnly}"
                    :error="errors.userForwardBack"
                    :error-messages="errors.userForwardBackErrorMessage"
                    :is-form-read-only="computedIsFormReadOnly"
                    label="Field Position"
                    hide-details="auto" 
                    :items="sortForwardBack"
                    v-model.trim="form.userForwardBack"/>
            <!--Player position-->
                <app-input input-type="select" v-if="form.userForwardBack !='N/A'"
                    style="width:100%;"
                    :class="{'ml-4': $vuetify.breakpoint.smAndUp, 'mt-4': $vuetify.breakpoint.xsOnly}"
                    :error="errors.userPlayerPosition"
                    :error-messages="errors.userPlayerPositionErrorMessage"
                    :is-form-read-only="computedIsFormReadOnly"
                    hide-details="auto" 
                    :items="sortPositions"
                    label="Positon"
                    v-model.trim="form.userPlayerPosition"/>
            </div>

             <div class="mt-4" v-if="['Staff-Admin' ].includes(form.userLevel)" no-gutters style="width: 100%">
                <!-- Configuration (User Type | User Role | User Status) -->
                <app-text category="text-medium" class="darkgrey--text">Access and Abilitites</app-text>
                <v-divider class="mt-2"/>
                <!--Device Notification Emails -->
            
                <app-input input-type="comboBox"
                    chips
                    class="mt-4 anotherclass"
                    deletable-chips
                    :disabled="computedIsFormReadOnly"
                    :is-form-read-only="computedIsFormReadOnly"
                    :items="accessAndAbilitiesData"
                    label="Access and Abilities"
                    multiple
                    small-chips
                    solo
                    eager
                    v-model="form.userAccessAndAbilities">
                    <template v-slot:item="data">
                        <app-text category="text-default">{{ data.item }}</app-text>
                    </template>
                </app-input>
            </div>

            <div class=" mt-4 " v-if="['Staff-Admin' ].includes(currUser)" no-gutters style="width: 100%">
                <!-- Configuration (User Type | User Role | User Status) -->
                <app-text category="text-medium" class="darkgrey--text">Configuration</app-text>
                <v-divider class="mt-2"/>
            </div>

            <div :class="$vuetify.breakpoint.width > 600 ? 'd-flex mt-4': 'mt-4 '" v-if="['Staff-Admin' ].includes(currUser)">
                <!--User type-->
                <app-input input-type="select"
                    :class="{'': $vuetify.breakpoint.smAndUp, 'mt-4': $vuetify.breakpoint.xsOnly}"
                    style="width:100%"
                    :is-form-read-only="computedIsFormReadOnly"
                    :items="sortLevels"
                    label="User Level"
                    v-model="form.userLevel">
                    <template v-slot:item="data">
                        <app-text category="text-default">{{ data.item }}</app-text>
                    </template>
                </app-input>

                <!--User status-->
                <app-input input-type="select"
                    :class="{'ml-4': $vuetify.breakpoint.smAndUp, 'mt-4': $vuetify.breakpoint.xsOnly}"
                    :is-form-read-only="computedIsFormReadOnly"
                    :items="sortUsersStatus"
                    label="User Status"
                    style="width:100%"
                    v-model="form.userStatus">
                    <template v-slot:item="data">
                        <app-text category="text-default">{{ data.item }}</app-text>
                    </template>
                </app-input>
            </div>

           <div class="mt-4" style="float:right">
                <app-btn v-if="!computedIsFormReadOnly"
                    @click.native="validateForm"
                    color="success"
                    icon="icons8-save"
                    label="SAVE"/>
           </div>
        </div>

    </v-form>
</template>

<script>

    import {mapGetters} from "vuex";
    import { doc, onSnapshot } from "firebase/firestore";
    export default {

        name: "userForm",

        props: ['doesFormStartReadOnly', 'formData'],

        data: () => ({
          
            sportTypeArray:['Rugby'],

            //User 
            userData: '',
            userCollectionData:'',
            userDataResult:'',

            //Erros
            errors: {
                userName: false,
                userNameErrorMessage: '',
                userEmail: false,
                userEmailErrorMessage: '',
                userTelephone: false,
                userTelephoneErrorMessage: '',
                userSportType:false,
                userSportTypeErrorMessage:'',
                userForwardBack:false,
                userForwardBackErrorMessage:'',
                userPlayerPosition:false,
                userPlayerPositionErrorMessage:'',
            },
            formReadOnly: true,
            form: {
                id: '',
                userProfile: '',
                userName: '',
                userSportType: '',
                userEmail: '',
                userTelephone: '',
                userLevel: '',
                userSportType: "",
                userForwardBack:"",
                userPlayerPosition:"",
                createdUserData: {},
                createdDateTime: '',
                modifiedUserData: {},
                modifiedDateTime: '',
            },
            isFormReadOnly: false,
            currUser:"",
            // Profile picture
            types: "image/*",
            storagePathProfilePic: "users-profile-pictures",
            photoUploadResult: {},
            tempImage: '',
        }),

        computed: {
            sortForwardBack(){
				return this.forwardBackArray.sort()
			},
            sortUsersStatus(){
				return this.userStatusOptionsData.sort()
			},
             sortPositions(){
				return this.playersPositions.sort()
			},
            sortLevels(){
                return this.userLevelsOptionsData.sort()
            },
            ...mapGetters({ 
                GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
                GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
            }),
            /**
             * Computed From Data
             *
             * If this is an existing document, set the form data.
             * If it's not, the default form will be used.
             */
            computedFormData() {
                const t = this
                const formData = t.$props.formData
                if (formData?.id) {
                    // Assign data
                    t.form = formData
                }
            },
            /**
             * Computed Is Form Read Only
             *
             * When the form is first created in the Supervisor's Control Panel,
             * the form should be editable.
             * If the form is open through the Reporting pages, it should open uneditable.
             *
             * @returns {boolean} is form read only
             */
            computedIsFormReadOnly() {
                const t = this
                return t.$props.doesFormStartReadOnly
                    ? t.$props.doesFormStartReadOnly
                    : t.isFormReadOnly
            },
        },
        methods: {
            /**
             * Get User Collection Data
             *
             * Fetch user collection data for the current user and sync it to the form.
             *
             * @returns {Promise<void>}
             */
            async getUserCollectionData() {
                const t = this
                let userData = await this.MIX_firestore_read(this.form.id, 'users'); 
                t.userCollectionData = userData.data
                t.form = userData.data
            },
            /**
             * Validate
             *
             * Validates the required fields for presence only.
             * If any of the fields are missing mark them in an errors object.
             * When there are no errors left, save the record.
             */
            validateForm() {
                const t = this
                const emailRegex = /.+@.+\..+/
                t.errors.userName = false
                t.errors.userNameErrorMessage = ''
                t.errors.userSportType = false
                t.errors.userSportTypeErrorMessage = ''
                t.errors.userEmail = false
                t.errors.userEmailErrorMessage = ''
                t.errors.userTelephone = false
                t.errors.userTelephoneErrorMessage = ''
                t.errors.userForwardBack = false
                t.errors.userForwardBackErrorMessage =''
                t.errors.userPlayerPosition = false,
                t.errors.userPlayerPositionMessage = ''
                // Name
                if (!t.form.userName.trim()) {
                    t.errors.userName = true
                    t.errors.userNameErrorMessage = 'Name required'
                }
                // Must be between 2 and 60 characters
                else if (t.form.userName.trim().length < 2 || t.form.userName.trim().length > 60) {
                    t.errors.userName = true
                    t.errors.userNameErrorMessage = 'Name must be between 2 and 60 characters'
                }
                // Sport Type 
                if (!t.form.userSportType.trim()) {
                    t.errors.userSportType = true
                    t.errors.userSportTypeErrorMessage = 'Sport type required'
                }
                // Field Position 
                if (!t.form.userForwardBack.trim()) {
                    t.errors.userForwardBack = true
                    t.errors.userForwardBackErrorMessage = 'Sport type required'
                }
                // Position
                if (!t.form.userPlayerPosition.trim() && t.form.userForwardBack !='N/A') {
                    t.errors.userPlayerPosition = true
                    t.errors.userPlayerPositionErrorMessage = 'Sport type required'
                }
                // Email
                if (!t.form.userEmail.trim()) {
                    t.errors.userEmail = true
                    t.errors.userEmailErrorMessage = 'Email required'
                }
                // Must be a (simple) valid email address
                else if (!emailRegex.test(t.form.userEmail)) {
                    t.errors.userEmail = true
                    t.errors.userEmailErrorMessage = 'Email not valid'
                }
                // User Contact Telephone
                if (!t.form.userTelephone.trim()) {
                    t.errors.userTelephone = true
                    t.errors.userTelephoneErrorMessage = 'Telephone Number required'
                }
                // Landline number must start 01, 02 or 03 and be either 10 or 11 digits
                else if (['1', '2', '3'].includes(t.form.userTelephone.trim()[1]) && (t.form.userTelephone.trim().length < 10 || t.form.userTelephone.trim().length > 11)) {
                    t.errors.userTelephone = true
                    t.errors.userTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
                }
                // Mobile number must start 07 and be 11 digits
                else if (['7'].includes(t.form.userTelephone.trim()[1]) && t.form.userTelephone.trim().length !== 11) {
                    t.errors.userTelephone = true
                    t.errors.userTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
                }
                // Number must start 01, 02, 03 or 07
                else if (!['0'].includes(t.form.userTelephone[0]) || ['0', '4', '5', '6', '8', '9'].includes(t.form.userTelephone[1])) {
                    t.errors.userTelephone = true
                    t.errors.userTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
                }
                // Check if there any errors left
                if (!Object.values(t.errors).includes(true)) {
                    t.saveDocument()
                }
            },
        
            /**
             * Save Document
             *
             * Update the user document.
             * Set the form to a viewable state.
             * Call to upload the profile picture if one has been selected.
             * Refresh the user data.
             *
             * @returns {Promise<void>}
             */
            async saveDocument() {
                const t = this
                const updateDocumentResult = await this.MIX_firestore_updateMultipleFields(this.form.id, 'users', t.form)
                if (updateDocumentResult.code === 1) {
                    t.formReadOnly = true
                    t.tempImage = ''
                    await t.uploadProfileImage()
                    this.userDataResult = await this.MIX_firestore_read(this.form.id, 'users');
                    // Refresh user data
                    await t.getUserCollectionData()
                }
                // Call for a confirmation alert
                t.renderConfirmationAlert(updateDocumentResult, 'Profile successfully updated', 'Error updating profile');
                if ( window.location.pathname === "/userProfile"){
                    this.MIX_go('/')
                }else{
                t.$emit('emitCloseRightPanel')
                }
            },
            /**
             * Upload Profile Image
             *
             * Update the user's document with a profile image path  (collection | user id | image path).
             *
             * @returns {Promise<void>}
             */
            async uploadProfileImage() {
                const t = this
                if (t.photoUploadResult !== {}) {
                    // Save to the document with: collection | user id | image path
                    const updatePhotosResult = await this.MIX_updateDocumentFieldsById('users', t.photoUploadResult.docLink, {userProfile: t.photoUploadResult.fileURL})
                    // Call for a confirmation alert
                    t.renderConfirmationAlert(updatePhotosResult, 'Photo successfully updated', 'Error updating photo')
                }
            },
            /**
             * Render Confirmation Alert
             *
             * Take the result of a document db change and render a confirmation box to the user.
             */
            renderConfirmationAlert(document, successMessage, failureMessage) {
                const t = this
                if (document.code === 1) {
                    t.MIX_alertBox({ color: "success", timeout: 3000, message: successMessage, show: true });
                } else {
                    t.MIX_alertBox({ color: "error", timeout: 3000, message: failureMessage, show: true });
                }
            },
        },
        watch:{
            /**
             * Photo Upload Result
             *
             * Watch for the result of a profile picture upload and add its storage path to the photoUploadResult variable.
             */
            GET_photoUploadResult: {
                handler: async function () {
                    const t = this
                    t.photoUploadResult = t.GET_photoUploadResult
                    t.tempImage = t.photoUploadResult.fileURL
                }, deep: true
            },
        },
        async mounted() {
            const t = this
            // Fetch required collection data
            await t.getUserCollectionData();
            let userDataResult = "";
            onSnapshot(doc(this.$firebase.db, 'users', this.form.id), (doc) => {
                userDataResult = doc.data();
                this.form.userProfile = userDataResult.userProfile;
            });

        },
        async created(){
            //GET CURR USER PRIVILAGES
            let currUserResult = await this.MIX_firestore_read(this.GET_FIREBASE_userAuth.uid, 'users');
            this.currUser = currUserResult.data.userLevel
            this.userDataResult = await this.MIX_firestore_read(this.form.id, 'users');
            this.userCollectionData = this.userDataResult.data
            this.form = this.userDataResult.data
        }
    }
</script>

<style scoped>

</style>
