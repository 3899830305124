import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import config from '@/config.js';

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'mdiSvg' | 'icons8' || 'mdi' // || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
	},
	theme: {
		options: { customProperties: true },
		themes: {
			light: {
				primary: config.primary,
				secondary: config.secondary,
				accent: config.accent,
				error: config.error,
				update: config.update,
				appBackground:config.appBackground,
				text: config.text
			},
		},
	},
});
