
export default {
	appname: 'Development Passport',
	organisation: 'Leeds Tykes',
	primary: "#122B84",
	secondary: "#F2C04C",
	accent: "#FBBE28",
	update: "#B8E110",
	error: "#b71c1c",
	adminEmail: "joncallard@jc16.co.uk",
	appBackground: "#f5f5f5",
	text: "#444444"
}
