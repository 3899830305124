<template>
	<div class="pa-4" style="height:100%">

		<!--Filters-->
		<div class="d-flex">
				
			<!--Name-->
			<app-input input-type="textInput"
					   style="width:100%;"
					   :label="$vuetify.breakpoint.width > 800 ? 'Search by name ': 'Name'"
					   :append-icon="'icons8-search'"
					   v-model.trim="search"/>

			<!--Position-->
			<app-input input-type="comboBox" v-if="$vuetify.breakpoint.width > 600"
					   chips
					   style="width:100%"
					   class="ml-4"
					   deletable-chips
					   :items="sortPositions"
					   :label="$vuetify.breakpoint.width > 800 ? 'Search by position ': 'Position'"
					   multiple
					   small-chips
					   solo
					   eager
					   v-model.trim="searchPosition">
				<template v-slot:item="data">
					<app-text category="text-default">{{ data.item }}</app-text>
				</template>
			</app-input>

            <!--IDP-->
             <app-input v-if="$vuetify.breakpoint.width > 600" input-type="select"
                :items="idpFilterArrray"
                item-text="text"
                item-value="value"
                style="width:100%;"
                class="ml-4"                                        
                label="IDP"
                clearable
                :append-icon="'icons8-search'"
                v-model="searchIdp"/>
		</div>

		<!--Cards-->
		<div class="cards">

			<div class="card" v-for="item in computedSessionsData" :key="item.id" @click="openPlayerIdp(item)">

				<!--Image-->
				<div class="d-flex align-center justify-center" style="aspect-ratio: 1/1 ; width: 100%;">

					<v-img v-if="item.userProfile!==''" :src="item.userProfile" style="aspect-ratio: 1/1"
						   class="rounded-t-lg"/>

					<app-icon v-else icon="users" color="secondary" size="180"/>

				</div>

				<!--Use Details-->
				<div class="pa-2">

					<!--Name | Position-->
					<div class="d-flex flex-column">

						<!--Name-->
						<app-text category="text-default-bold" class="text-center justify-center">
							{{ item.userName }}
						</app-text>

						<!--Position-->
						<app-text category="text-small" class="text-center justify-center">
							{{ item.userPlayerPosition }}
						</app-text>

					</div>

					<v-divider class="mt-2"/>

					<!--Sessions | Matches | IDP-->
					<div class="d-flex align-center mt-2">

						<!--Sessions-->
						<app-icon icon="stopwatch" color="grey darken-2" size="24"/>
						<app-text category="text-medium-bold" class="text-center ml-2 primary--text">
							{{ item.sessionsNo }}
						</app-text>

						<!--Matches-->
						<app-icon class="ml-4" icon="matchIcon" color="grey darken-2" size="24"/>
						<app-text category="text-medium-bold" class="text-center justify-center ml-2 primary--text">
							{{ item.matchesNo }}
						</app-text>

						<!--IDP-->
						<app-icon class="ml-4" icon="grid" color="grey darken-2" size="24"/>
						<app-icon class="ml-2"
								  :icon="item.idpCompleted ? 'checkmarkYes' : 'idpUncomplete'"
								  :color="item.idpCompleted ? 'green' : 'red'"
								  size="24"/>

					</div>

				</div>

			</div>

		</div>

	</div>

</template>

<script>

export default {
	name: "SquadPlayers",

	props: ['usersData', 'formData'],

	data: () => ({
		filterTeam: "",
		search: "",
        searchIdp:null,
        idpFilterArrray: [ {text:"Complete", value:true} ,
                            {text:"Incomplete", value: false},
                            // {text:"All ", value:null}
                            ],
		searchPosition: [],
	}),

	computed: {

		sortPositions() {
			const t = this

			return t.playersPositions.sort()
		},

		computedSessionsData() {
			const t = this
			let tableData = t.usersData
			if (t.search != null) {
				tableData = tableData.filter(data =>
					data.userName.toUpperCase().match(t.search.toLocaleUpperCase()))
			}

            if(t.searchIdp != null){
                tableData = tableData.filter(data =>
				data.idpCompleted === t.searchIdp)
            }

			if (t.searchPosition.length > 0) {
				tableData = tableData.filter(data =>
					t.searchPosition.includes(data.userPlayerPosition))
			}

			// Sort alphabetically
			tableData = tableData.sort((a, b) => {
				return a.userName > b.userName ? 1 : -1
			})
			return tableData
		},

	},

	methods: {

		openPlayerIdp(item) {
			this.$emit('selectedPlayer', item);
		}
	},

	

}

</script>

<style scoped>
html {
	font-size: 22px;
}

body {
	padding: 1rem;
}

.sessionsNo {
	background: #122B84;
	width: 40px;
	height: 40px;
	border-radius: 4px;
	padding: 2px;
}

.card {
	display: flex;
	flex-direction: column;
	align-items: center;

	background-color: #DDDDDD;
	border-radius: 8px;
	min-height: 256px;
	/*width: 200px;*/
}

.cards {
	display: grid;
	gap: 16px;
	grid-template-columns: repeat( auto-fill, minmax(200px, 1fr));

	margin-top: 16px;
}

</style>
