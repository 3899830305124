import { render, staticRenderFns } from "./squad.vue?vue&type=template&id=811f710a&scoped=true&"
import script from "./squad.vue?vue&type=script&lang=js&"
export * from "./squad.vue?vue&type=script&lang=js&"
import style0 from "./squad.vue?vue&type=style&index=0&id=811f710a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "811f710a",
  null
  
)

export default component.exports